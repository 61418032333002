import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik"
import * as Yup from "yup"
import api from "@utils/api.client" // Replace with your API endpoint/module
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

/* -------------------------------------------------------------------------
   1) STYLED COMPONENTS : Layout & basic styles (Short Contact Page)
--------------------------------------------------------------------------- */

const PageContainer = styled.main`
  width: 100%;
  min-height: 87vh;
  background: url("/img/warehousing.png") no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Helvetica Neue", Arial, sans-serif;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`

const StyledFormikForm = styled(FormikForm)`
  width: 100%;
  /* max-width: 500px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input,
  textarea,
  select {
    color: ${({ theme }) => theme.tertiary};
  }
`

const FormContainer = styled.section`
  position: relative;
  z-index: 1; /* Above the overlay */
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 40px 20px;
  width: 100%;
  max-width: 550px;
  margin: 0 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #fff;
`

const Title = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
`

const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #ccc;
`

/* -------------------------------------------------------------------------
   2) ANIMATIONS & FORM-RELATED ELEMENTS
--------------------------------------------------------------------------- */

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

/** StepWrapper: element fade-in for each step */
const StepWrapper = styled.div`
  animation: ${fadeIn} 0.5s ease-out;
`

/** Styled selector for subject choice (step 1) */
const Select = styled.select`
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 16px;
  background: #fff;
  color: #333;
`

/** Multiline text area */
const TextArea = styled.textarea`
  padding: 14px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
`

/** Submission (or step navigation) button */
const Button = styled.button`
  padding: 14px 24px;
  background: ${(props) => (props.empty ? "transparent" : "#b11116")};
  border: ${(props) => (props.empty ? "2px solid #b11116" : "none")};
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background: ${(props) => (props.empty ? "rgba(177,17,22,0.1)" : "#d01318")};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

/** Standard input field */
const Input = styled.input`
  padding: 14px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
`

/** Validation error text */
const ErrorText = styled.div`
  color: #ffdddd;
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 8px;
`

/** Success banner to confirm form submission */
const SuccessBanner = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  animation: ${fadeIn} 0.5s ease-out;
  z-index: 2;
`

/* -------------------------------------------------------------------------
   3) FORM LOGIC (Formik + Yup)
      - Step 1: Subject selection (user's choice, now stored as "subject")
      - Step 2: Detailed contact form
--------------------------------------------------------------------------- */

// Set the constant inquiryType for the contact form.
const fixedInquiryType = "CONTACT"

// Include the subject field and the language field.
// The language is derived from i18next.
const initialValues = {
  inquiryType: fixedInquiryType, // Always "CONTACT" for the backend
  subject: "", // User's selection (e.g., "renseignement", "support", or "autre")
  language: "", // Will be set from i18n
  name: "",
  email: "",
  phoneNumber: "",
  message: "",
}

const getValidationSchema = (t) =>
  Yup.object({
    subject: Yup.string().required(t("contact.validation.subjectRequired")),
    name: Yup.string().required(t("contact.validation.nameRequired")),
    email: Yup.string().email(t("contact.validation.emailInvalid")).required(t("contact.validation.emailRequired")),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, t("contact.validation.phoneInvalid"))
      .required(t("contact.validation.phoneRequired")),
    message: Yup.string().required(t("contact.validation.messageRequired")),
    language: Yup.string().required(), // Ensure language is present
  })

/* -------------------------------------------------------------------------
   4) MAIN COMPONENT: GeneralContactForm
--------------------------------------------------------------------------- */

function GeneralContactForm() {
  const { t, i18n } = useTranslation("contact")
  const [step, setStep] = useState(1)
  // Use subject for the user's choice; default empty string.
  const [subject, setSubject] = useState("")
  const [successMessage, setSuccessMessage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()

  // Update initialValues with the current language from i18n
  const formInitialValues = {
    ...initialValues,
    subject,
    language: i18n.language, // Pass the current language
  }

  const validationSchema = getValidationSchema(t)

  // Check that a subject has been chosen before proceeding to step 2
  const handleStepOneNext = () => {
    if (!subject) {
      alert(t("contact.step1.alertNoSubject"))
      return
    }
    setStep(2)
  }

  // Go back to the previous step
  const handleBack = () => {
    setStep(1)
  }

  // Final form submission
  const handleSubmit = async (values, { resetForm }) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      // POST to the "/contact" endpoint (adapt to your API)
      const response = await api.post("/inquiries/contact", values)
      console.log("Form submitted successfully:", response)

      navigate(t("cta.successRedirectUrl"))

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(true)
        resetForm()
        // Hide the success banner after 3 seconds
        setTimeout(() => setSuccessMessage(false), 3000)
      }
    } catch (error) {
      console.error("Error submitting the form:", error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <PageContainer>
      {/* Dark overlay in the background */}
      <Overlay />

      {/* Success banner if the message was sent */}
      {successMessage && <SuccessBanner>{t("contact.successMessage")}</SuccessBanner>}

      {/* Main form container with a frosted-glass effect */}
      <FormContainer>
        {step === 1 && (
          <StepWrapper>
            <Title>{t("contact.step1.title")}</Title>
            <Subtitle>{t("contact.step1.subtitle")}</Subtitle>
            <Select value={subject} onChange={(e) => setSubject(e.target.value)}>
              <option value="">{t("contact.step1.selectDefault")}</option>
              <option value="renseignement">{t("contact.step1.options.information")}</option>
              <option value="support">{t("contact.step1.options.services")}</option>
              <option value="autre">{t("contact.step1.options.other")}</option>
            </Select>

            <Button type="button" onClick={handleStepOneNext}>
              {t("contact.step1.buttonNext")}
            </Button>
          </StepWrapper>
        )}

        {step === 2 && (
          <StepWrapper>
            <Title>{t("contact.step2.title")}</Title>
            <Subtitle>{t("contact.step2.subtitle")}</Subtitle>

            <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              <StyledFormikForm
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {/* Hidden field to ensure inquiryType is always sent as "CONTACT" */}
                <Field type="hidden" name="inquiryType" value={fixedInquiryType} />
                {/* Hidden field for subject */}
                <Field type="hidden" name="subject" value={subject} />
                {/* Hidden field for language */}
                <Field type="hidden" name="language" value={i18n.language} />

                {/* First Name / Last Name Fields */}
                <div style={{ display: "flex", gap: "8px" }}>
                  <div style={{ flex: 1, textAlign: "left" }}>
                    <label htmlFor="name">{t("contact.step2.labels.name")}</label>
                    <Field as={Input} id="name" name="name" placeholder={t("contact.step2.placeholders.name")} />
                    <ErrorMessage name="name" component={ErrorText} />
                  </div>
                </div>

                {/* Email / Phone Fields */}
                <div style={{ display: "flex", gap: "8px" }}>
                  <div style={{ flex: 1, textAlign: "left" }}>
                    <label htmlFor="email">{t("contact.step2.labels.email")}</label>
                    <Field as={Input} id="email" name="email" placeholder={t("contact.step2.placeholders.email")} />
                    <ErrorMessage name="email" component={ErrorText} />
                  </div>

                  <div style={{ flex: 1, textAlign: "left" }}>
                    <label htmlFor="phoneNumber">{t("contact.step2.labels.phoneNumber")}</label>
                    <Field as={Input} id="phoneNumber" name="phoneNumber" placeholder={t("contact.step2.placeholders.phoneNumber")} />
                    <ErrorMessage name="phoneNumber" component={ErrorText} />
                  </div>
                </div>

                {/* Message Field */}
                <div style={{ textAlign: "left" }}>
                  <label htmlFor="message">{t("contact.step2.labels.message")}</label>
                  <Field as={TextArea} id="message" name="message" rows="5" placeholder={t("contact.step2.placeholders.message")} />
                  <ErrorMessage name="message" component={ErrorText} />
                </div>

                {/* Navigation Buttons (Back / Submit) */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1rem",
                    marginTop: "8px",
                  }}
                >
                  <Button type="button" onClick={handleBack} empty>
                    {t("contact.step2.buttonBack")}
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? t("contact.step2.submitting") : t("contact.step2.buttonSubmit")}
                  </Button>
                </div>
              </StyledFormikForm>
            </Formik>
          </StepWrapper>
        )}
      </FormContainer>
    </PageContainer>
  )
}

export default GeneralContactForm
