import { DefaultTheme } from "styled-components"

export const themeSettings = {
  modes: {
    light: {
      mode: "light",

      // Core Brand Colors
      primary: "#E8231A",
      primaryLight: "#ED514A", // Lighter shade of primary
      primaryDark: "#90140E", // Darker shade of primary

      // Accent Colors
      secondary: "#CBCBCB", // grey
      tertiary: "#1E1E1E", // black
      highlight: "#FFE07D",

      // Status Colors
      success: "#43CD80",
      warning: "#f0ad4e",
      danger: "#DA2C38",

      // Neutral Colors
      white: "#FFFFFF",
      black: "#000000",
      grey: "#ACA9A9",
      lightGrey: "#F1F1F1",
      darkGrey: "#2E2E39",

      // Text Colors
      textPrimary: "#2E2E39",
      textSecondary: "#888c94",
      link: "#247BA0",

      // Background Colors
      backgroundPrimary: "#F6f6f6",
      backgroundSecondary: "#F2F2F4",
      backgroundTertiary: "#FFFFFF",
    },

    dark: {
      mode: "dark",

      // Core Brand Colors (Dark Mode Variants)
      primary: "#337abd",
      primaryLight: "#3E7B57",
      primaryDark: "#142F22",

      // Accent & Secondary Colors (Dark Mode)
      secondary: "#969696",
      tertiary: "#4A4A4A",
      highlight: "#FFC861",

      // Status Colors
      success: "#2D8A5F",
      warning: "#B97D29",
      danger: "#C42D37",
      info: "#1D6F7D",

      // Neutral Colors
      white: "#FFFFFF",
      black: "#000000",
      grey: "#969696",
      lightGrey: "#3A3A3A",
      darkGrey: "#171717",

      // Text Colors
      primaryText: "#F9F9F7",
      secondaryText: "#969696",
      link: "#78DDE2",

      // Background Colors (Dark Mode)
      backgroundPrimary: "#0F1012",
      backgroundSecondary: "#171717",
      backgroundTertiary: "#282A44",
    },
  },

  // Shared Typography Tokens (using rem units)
  typography: {
    fontFamily: {
      headings: "Outfit, sans-serif",
      body: "Work Sans, sans-serif",
    },
    fontSize: {
      xs: "0.75rem", // 12px
      sm: "0.875rem", // 14px
      md: "1rem", // 16px
      lg: "1.125rem", // 18px
      xl: "3.05rem;", // 24px
      xxl: "3.75rem", // 32px
    },
    fontWeight: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
  },

  // Spacing Scale (using rem units)
  spacing: {
    xs: "0.25rem", // 4px
    sm: "0.5rem", // 8px
    md: "1rem", // 16px
    lg: "1.5rem", // 24px
    xl: "2rem", // 32px
    xxl: "3rem", // 48px
  },

  // Breakpoints
  breakpoints: {
    xs: "0px",
    sm: "36rem", // 576px
    md: "48rem", // 768px
    lg: "62rem", // 992px
    xl: "75rem", // 1200px
    tablet: "48rem",
    desktop: "62rem",
    widescreen: "75rem",
    extraWidescreen: "87.5rem", // 1400px
  },

  // Component-Level Tokens
  components: {
    borderRadius: {
      small: "0.25rem", // 4px
      medium: "0.5rem", // 8px
      large: "0.75rem", // 12px
      round: "50%",
    },
    boxShadow: {
      small: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.1)", // 0px 2px 4px
      medium: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.15)", // 0px 4px 8px
      large: "0 0.375rem 0.75rem rgba(0, 0, 0, 0.2)", // 0px 6px 12px
    },
    transitionSpeed: "400ms",
  },

  // Z-Index Layers
  zIndex: {
    base: 0,
    dropdown: 100,
    overlay: 1000,
    modal: 1050,
    popover: 1100,
    tooltip: 1200,
  },
}

/**
 * Merges the light/dark mode colors with the shared tokens,
 * returning a complete theme object that matches DefaultTheme.
 */
export const buildTheme = (mode: "light" | "dark"): DefaultTheme => {
  const { modes, ...sharedTokens } = themeSettings
  const modeColors = modes[mode]

  return {
    ...modeColors,
    ...sharedTokens,
  } as DefaultTheme
}

export default themeSettings
