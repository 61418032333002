import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// Reusable components (assumed to be created elsewhere)
import Popup from "@components/layout/Popup"
import ReusableHero from "@components/layout/Hero/ReusableHero"
import ReusableServicesOverview from "@components/layout/sections/ReusableServicesOverview"
import { getQuoteFormSchema, renderQuoteForm } from "@components/layout/sections/ReusableCallToAction/Quote"
import ReusableCallToAction from "@components/layout/sections/ReusableCallToAction"
import { Heading } from "@components/base"
import { motion } from "framer-motion"
import { AnimatePresence } from "framer-motion"
import FullScreenSliderSection from "@components/layout/sections/FullScreenSlider"
import LogoMarkRef from "@assets/img/LogoMark.svg"
import { Layout } from "../Brisson3PL"
import api from "@utils/api.client"
import { ProfessionalFeatureSection } from "./ProfessionalFeatureSection"
import { ModernStorageSection } from "./ModernStorageSection"

const ArtSolutions = () => {
  const { t, ready } = useTranslation("services/artSolutions")
  const navigate = useNavigate()

  if (!ready) return <div />

  // Retrieve translation objects (assumed to be arrays or objects)
  const servicesOverviewData = t("servicesOverview", { returnObjects: true })
  servicesOverviewData.cards = servicesOverviewData.cards.map((card) => ({
    ...card,
    logo: "/img/Logo-white-Art-Solution.svg",
  }))

  const whyChooseData = t("whyChoose", { returnObjects: true })
  const storageData = t("premiumStorage", { returnObjects: true })

  const artSolutionsSlider = [t("solutions.transport"), t("solutions.customs"), t("solutions.storage"), t("solutions.packaging")]

  const initialValues = { inquiryType: "MOVING", name: "", email: "", phoneNumber: "", message: "" }

  // Submission handler
  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post("/inquiries/art", values)
      console.log(t("cta.submitted"), values)
      if (response.status === 200 || response.status === 201) {
        actions.resetForm()
        actions.setSubmitting(false)
        // Optionally navigate to a thank you page:
        navigate(t("cta.successRedirectUrl"))
      }
    } catch (error) {
      console.error("Error submitting moving inquiry:", error)
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <meta name="keywords" content={t("meta.keywords")} />
        <meta property="og:title" content={t("meta.ogTitle")} />
        <meta property="og:description" content={t("meta.ogDescription")} />
        <meta property="og:image" content={t("meta.ogImage")} />
        <meta property="og:url" content={t("meta.ogUrl")} />
      </Helmet>

      <Layout>
        <ReusableHero
          headingPre={t("hero.headingPre")}
          headingHighlight={t("hero.headingHighlight")}
          headingPost={t("hero.headingPost")}
          subtitle={t("hero.subtitle")}
          imageSrc="/img/love-transport.png"
          imageAlt={t("hero.imageAlt")}
          ctaLabel={t("hero.ctaLabel")}
          ctaOnClick={() => navigate("/faire-une-demande")}
        />

        <ReusableServicesOverview
          bgImg="/img/art-solution-art-transport.png"
          hasOverlay
          cardsData={servicesOverviewData.cards}
          autoRotateInterval={5000}
        />

        <ProfessionalFeatureSection data={whyChooseData} />

        {/* <FullScreenSliderSection slides={artSolutionsSlider} /> */}

        <ModernStorageSection data={storageData} />

        <ReusableCallToAction
          inquiryType="ART"
          heading={t("cta.heading")}
          text={t("cta.text")}
          initialValues={initialValues}
          validationSchema={getQuoteFormSchema(t)}
          onSubmit={handleSubmit}
          renderForm={(formikProps) => renderQuoteForm(formikProps, t)}
          portraitSrc="/img/art-cta.png"
          logoMarkSrc="/img/LogoMark.svg"
          transformPortraitImg="translateY(10vh)"
        />
      </Layout>
    </>
  )
}

export default ArtSolutions
