import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// Reusable components (assumed to be created elsewhere)
import Popup from "@components/layout/Popup"
import ReusableHero from "@components/layout/Hero/ReusableHero"
import ReusableServicesOverview from "@components/layout/sections/ReusableServicesOverview"
// import CallToAction from "./CallToAction"
import { getQuoteFormSchema, renderQuoteForm } from "@components/layout/sections/ReusableCallToAction/Quote"
import ReusableCallToAction from "@components/layout/sections/ReusableCallToAction"
import { Heading } from "@components/base"
import { motion } from "framer-motion"
import { AnimatePresence } from "framer-motion"
import FullScreenSliderSection from "@components/layout/sections/FullScreenSlider"
import LogoMarkRef from "@assets/img/LogoMark.svg"
import api from "@utils/api.client"
import Col from "@components/layout/Col"

// ------------------------------------------------------------------
// Basic Layout & Section Wrappers
// ------------------------------------------------------------------

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
`

/**
 * A generic Section with optional background color or image.
 */
const Section = styled.section<{ bgColor?: string; bgImage?: string }>`
  width: 100%;
  padding: 60px 20px;
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : "none")};
  background-size: cover;
  background-position: center center;
  position: relative;

  @media (min-width: 768px) {
    padding: 80px 40px;
  }
`

/**
 * A container to center content and limit width.
 */
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

/**
 * Section Title styling.
 */
const SectionTitle = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme?.tertiary || "#333"};
  text-align: center;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 2.8rem;
  }
`

/**
 * Section Subtitle styling.
 */
const SectionSubtitle = styled.h3`
  font-size: 1.5rem;
  margin: 1.5rem 0 0.75rem;
  color: ${({ theme }) => theme?.primary || "#666"};
  /* text-align: center; */
  font-weight: 500;
`

// --- Common Card Title and Text ---
const CardTitle = styled.h3`
  font-size: 1.75rem;
  margin: 0.5rem 0;
  color: ${({ theme }) => theme?.primary || "#007BFF"};
  font-weight: 600;
`

const CardText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
`

// --- Solutions Slider (Horizontal Scroll) ---
const SolutionsSlider = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 1rem;
  padding: 1rem 0;
  margin-top: 2rem;

  /* Hide scrollbar if desired */
  &::-webkit-scrollbar {
    display: none;
  }
`

const SolutionCard = styled.div`
  flex: 0 0 250px;
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
`

// --- Why Choose Grid with Icons ---
const WhyChooseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`

const WhyChooseCard = styled.div`
  background: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`

const Icon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
`

/**
 * Paragraph styling.
 */
const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.7;
  margin: 2rem 0;
  margin-bottom: 1rem;
  color: #333;
  ${({ center }) => center && "text-align: center;"}

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`
const BulletList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 0;
  margin: 2rem auto;
  max-width: 1000px;
  list-style: none;
  color: #333;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const BulletItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  font-size: 1.15rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: 500;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  /* Container for the bullet number with its circle */
  .bullet-content {
    position: relative;
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  /* The main badge (number) */
  .bullet-number {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme?.primary || "#007BFF"};
    /* background-color: ${({ theme }) => theme?.primary || "#007BFF"}; */
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }

  /* The outer decorative circle, offset a bit */
  .bullet-circle {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Offsets the circle slightly */
    width: 50px;
    height: 50px;
    background: ${({ theme }) => theme?.primary + 20 || "#007BFF"};
    /* border: 2px solid ${({ theme }) => theme?.primary || "#007BFF"}; */
    border-radius: 50%;
    opacity: 0.8;
  }
`

/**
 * CTA button container.
 */
const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  align-items: center;

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: center;
  }
`

const CTAButton = styled.button`
  background: ${({ theme }) => theme?.primary || "linear-gradient(82deg, #d0021b, #f5a623, #f5a623, #d0021b)"};
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  min-width: 200px;

  &:hover {
    background: #ff3333;
  }
`

const LinkButton = styled.a`
  display: inline-block;
  background: #fff;
  color: #333;
  border: 2px solid ${({ theme }) => theme?.primary || "#333"};
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  min-width: 200px;
  text-align: center;
  transition:
    background 0.3s,
    color 0.3s;

  &:hover {
    background: ${({ theme }) => theme?.primary || "#333"};
    color: #fff;
  }
`

// SECTION CONTAINER
const FeatureSection = styled.section`
  padding: 80px 20px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
`

// CONTENT CONTAINER
const FeatureContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

// SECTION HEADER
const SectionHeading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

const SectionSubheading = styled.p`
  text-align: center;
  font-size: 1.125rem;
  color: #555;
  margin-bottom: 2rem;
`

// GRID LAYOUT FOR FEATURE CARDS
const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    & > :last-child {
      grid-column: 1 / -1; /* Span full width */
    }
  }
`

// FEATURE CARD
const FeatureCard = styled.div`
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 2rem 1.5rem;
  text-align: center;
  overflow: hidden;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`

// CUSTOM SHAPE ELEMENT (DIAMOND)
const CustomShape = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  background: linear-gradient(135deg, ${({ theme }) => theme?.primary || "#007bff"}, ${({ theme }) => theme?.primaryDark || "#0056b3"});
  clip-path: polygon(50% 0%, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%);
  opacity: 0.15;
  z-index: 0;

  transition: clip-path 0.3s;
  &:hover {
    transition: clip-path 0.3s;

    clip-path: polygon(50% 0%, 90% 10%, 100% 50%, 90% 90%, 50% 100%, 10% 90%, 0% 50%, 10% 10%);
  }
`

// LOGO MARK (made smaller)
const LogoMark = styled(LogoMarkRef)`
  width: 7rem;
  height: 7rem;
  margin: 0 auto 1rem auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

// FEATURE TITLE
const FeatureTitle = styled.h3`
  font-size: 1.8rem;
  margin-top: 60px; /* Ensure space for the custom shape */
  margin-bottom: 1rem;
  color: ${({ theme }) => theme?.primary || "#007bff"};
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
`

// FEATURE DESCRIPTION
const FeatureDescription = styled.p`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  position: relative;
  z-index: 1;
`

// PROFESSIONAL FEATURE SECTION COMPONENT
// Data is expected to have keys: title, subheading, and feature objects: cost, flexibility, security, expertise.
export const ProfessionalFeatureSection = ({ data }) => {
  const features = [data.flexibility, data.security, data.expertise, data.cost]

  return (
    <FeatureSection>
      <FeatureContainer>
        <SectionHeading>{data.title}</SectionHeading>
        <SectionSubheading>{data.subheading}</SectionSubheading>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <CustomShape />
              <LogoMark />
              <FeatureTitle className="gradient-text1">{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.text}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeatureGrid>
      </FeatureContainer>
    </FeatureSection>
  )
}

// -----------------------------
// Section 6: Modern Premium Storage Section
// -----------------------------
const StorageSection = styled.section`
  padding: 60px 20px;
  background-color: #f5f5f5;
`

const StorageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const StorageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const StorageCard = styled.div`
  background: #fff;
  background: ${({ theme }) => theme?.tertiary || "#fff"};

  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
`

const StorageImage = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
`

const StorageContent = styled.div`
  padding: 1rem 1.5rem;
`

const StorageTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: white;
`

const StorageText = styled.p`
  font-size: 1rem;
  color: #b7b7b7;
`

export const ModernStorageSection = ({ data }) => {
  // data is expected to be an object with keys: title, subheading, and storage cards: docks, climate, inventory, vip.
  const cards = [data.climate, data.inventory, data.vip]
  return (
    <StorageSection>
      <StorageContainer>
        <Heading level={2} center>
          {data.title}
        </Heading>
        <p style={{ textAlign: "center", fontSize: "1.125rem", color: "#555" }}>{data.subheading}</p>
        <StorageGrid>
          {cards.map((card, index) => (
            <StorageCard key={index}>
              <StorageImage src={card.image} alt={card.title} />
              <StorageContent>
                <StorageTitle className="">{card.title}</StorageTitle>
                <StorageText>{card.text}</StorageText>
              </StorageContent>
            </StorageCard>
          ))}
        </StorageGrid>
      </StorageContainer>
    </StorageSection>
  )
}

// ------------------------------------------------------------------
// Main Page Component
// ------------------------------------------------------------------
export const Brisson3Pl: React.FC = () => {
  const { t, ready } = useTranslation("services/brisson3pl")
  const navigate = useNavigate()
  const [isPopupVisible, setPopupVisible] = useState(false)
  const popupTriggered = useRef(false)

  if (!ready) {
    return <div></div>
  }

  const servicesOverviewData = t("servicesOverview", { returnObjects: true })
  const cardsData = Array.isArray(servicesOverviewData.cards) ? servicesOverviewData.cards : []

  const whyChooseData = t("whyChoose", { returnObjects: true })
  const premiumStorageData = t("premiumStorage", { returnObjects: true })

  const solutionsData = t("solutions", { returnObjects: true })
  const solutionsSlider = [solutionsData.transport, solutionsData.storage, solutionsData.crating, solutionsData.customs]

  const initialValues = { inquiryType: "3PL", name: "", email: "", phoneNumber: "", message: "" }

  // Submission handler
  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post("/inquiries/3pl", values)
      console.log(t("cta.submitted"), values)
      if (response.status === 200 || response.status === 201) {
        actions.resetForm()
        actions.setSubmitting(false)
        // Optionally navigate to a thank you page:
        navigate(t("cta.successRedirectUrl"))
      }
    } catch (error) {
      console.error("Error submitting moving inquiry:", error)
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <meta name="keywords" content={t("meta.keywords")} />
        <meta name="robots" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={t("meta.ogTitle")} />
        <meta property="og:description" content={t("meta.ogDescription")} />
        <meta property="og:image" content={t("meta.ogImage")} />
        <meta property="og:url" content={t("meta.ogUrl")} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("meta.ogLocale")} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("meta.twitterTitle")} />
        <meta name="twitter:description" content={t("meta.twitterDescription")} />
        <meta name="twitter:image" content={t("meta.twitterImage")} />
      </Helmet>

      <Layout>
        {/* 1) Hero Section */}
        <ReusableHero
          headingPre={t("hero.headingPre")}
          headingHighlight={t("hero.headingHighlight")}
          headingPost={t("hero.headingPost")}
          subtitle={t("hero.subtitle")}
          imageSrc="/img/3pl-warehouse.jpg"
          imageAlt={t("hero.imageAlt")}
          ctaLabel={t("hero.ctaLabel")}
          ctaOnClick={() => navigate("/faire-une-demande")}
        />
        <ReusableServicesOverview bgImg={"/img/3pl-warehouse4.jpg"} hasOverlay cardsData={cardsData} autoRotateInterval={5000} />
        {/* 3) Intro Section */}
        <Section>
          <Container>
            <Heading level={2} center>
              {t("solutionsSection.title")}
            </Heading>
            <Paragraph center>{t("solutionsSection.subheading")}</Paragraph>
            <BulletList>
              <BulletItem>
                <span>
                  {" "}
                  <div className="bullet-content">
                    <div className="bullet-number gradient-text1">1</div>
                    <div className="bullet-circle"></div>
                  </div>
                  {t("solutions.transport.title")}
                </span>
                {t("solutions.transport.text")}
              </BulletItem>
              <BulletItem>
                <span>
                  <div className="bullet-content">
                    <div className="bullet-number gradient-text1">2</div>
                    <div className="bullet-circle"></div>
                  </div>
                  {t("solutions.storage.title")}
                </span>
                {t("solutions.storage.text")}
              </BulletItem>
              <BulletItem>
                <span>
                  {" "}
                  <div className="bullet-content">
                    <div className="bullet-number gradient-text1">3</div>
                    <div className="bullet-circle"></div>
                  </div>
                  {t("solutions.crating.title")}
                </span>
                {t("solutions.crating.text")}
              </BulletItem>
              <BulletItem>
                <span>
                  {" "}
                  <div className="bullet-content">
                    <div className="bullet-number gradient-text1">4</div>
                    <div className="bullet-circle"></div>
                  </div>
                  {t("solutions.customs.title")}
                </span>
                {t("solutions.customs.text")}
              </BulletItem>
            </BulletList>
          </Container>
        </Section>

        {/* <Col width="70vw">
          <Heading level={2} center>
            {t("solutionsSection.title")}
          </Heading>
          <Paragraph
            style={{
              margin: "0.6rem",
              textAlign: "center",
            }}
          >
            {t("solutionsSection.subheading")}
          </Paragraph>
        </Col> */}

        {/* <FullScreenSliderSection slides={solutionsSlider} /> */}

        <ProfessionalFeatureSection data={whyChooseData} />

        <ModernStorageSection data={premiumStorageData} />

        <ReusableCallToAction
          inquiryType="3PL"
          heading={t("cta.heading")}
          text={t("cta.text")}
          initialValues={initialValues}
          validationSchema={getQuoteFormSchema(t)}
          onSubmit={handleSubmit}
          renderForm={(formikProps) => renderQuoteForm(formikProps, t)}
          portraitSrc="/img/cta-persona.png"
          logoMarkSrc="/img/LogoMark.svg"
        />
      </Layout>

      {/* {isPopupVisible && <Popup onClose={() => setPopupVisible(false)} />} */}
    </>
  )
}

export default Brisson3Pl
