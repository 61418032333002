import React from "react"
import styled, { keyframes } from "styled-components"
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

// ==================================
// STYLED COMPONENTS & ANIMATIONS
// ==================================
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const CtaSection = styled.section`
  width: 100%;
  height: 90vh;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: auto;
    padding: 40px 20px;
  }
`

const CtaContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin-left: 2rem;

  @media (max-width: 900px) {
    max-width: 100%;
    text-align: center;
    margin-left: 0rem;
  }
`

const CtaHeading = styled.h2`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
  line-height: 1.2;
  color: #fff;

  @media (min-width: 1200px) {
    font-size: 3rem;
  }
`

const CtaText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #ccc;
`

// We style native elements so that Formik properly registers values.
const StyledForm = styled(FormikForm)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
  }
`

const EmailField = styled.input`
  flex: 1;
  padding: 12px 16px;
  font-size: 1rem;
  border: 1.5px solid ${({ theme }) => theme?.primary || "#007BFF"};
  outline: none;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme }) => theme?.black || "#333"};
  color: ${({ theme }) => theme?.secondary || "#fff"};

  @media (max-width: 900px) {
    border-radius: 4px;
    width: 100%;
  }
`

const StyledSelect = styled.select`
  padding: 12px 16px;
  font-size: 1rem;
  border: 1.5px solid ${({ theme }) => theme?.primary || "#007BFF"};
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.black || "#333"};
  color: ${({ theme }) => theme?.secondary || "#fff"};
`

const TextArea = styled.textarea`
  padding: 12px 16px;
  font-size: 1rem;
  border: 1.5px solid ${({ theme }) => theme?.primary || "#007BFF"};
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.black || "#333"};
  color: ${({ theme }) => theme?.secondary || "#fff"};
  resize: vertical;
`

const StyledErrorMessage = styled.div`
  color: #ff6b6b;
  font-size: 0.9rem;
`

const SubmitButton = styled.button`
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: ${({ theme }) => theme?.primary || "#007BFF"};
  border: 1.5px solid ${({ theme }) => theme?.primary || "#007BFF"};
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (max-width: 900px) {
    border-radius: 4px;
    width: 100%;
  }

  &:hover {
    opacity: 0.9;
  }
`

const RightColumn = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    /* margin-top: -15vh; */
    max-width: 100%;
  }
`

const PortraitImage = styled.img`
  width: 100%;
  max-width: 35vw;
  transform: ${({ transformPortraitImg }) => (transformPortraitImg ? transformPortraitImg : "translateY(32vh)")};
  height: auto;
  object-fit: cover;
  border: none;
  border-radius: 4px;
  filter: grayscale(100%);

  @media (max-width: 900px) {
    max-width: 100%;
    transform: translateY(0);
  }
`

const LogoMark = styled.img`
  position: absolute;
  bottom: 14vh;
  right: 2rem;
  width: 180px;
  height: auto;
  transform: translate(25%, 25%);
`

// Banners
const SuccessBanner = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  animation: ${fadeIn} 0.5s ease-out;
  z-index: 2;
`

const ErrorBanner = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #ff6b6b;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  animation: ${fadeIn} 0.5s ease-out;
  z-index: 2;
`

// ==================================
// 3) REUSABLE CALL-TO-ACTION COMPONENT
// ==================================
/**
 * ReusableCallToAction component
 *
 * Props:
 * - inquiryType (optional): The inquiry type for the form (e.g., "MOVING", "GENERAL_QUOTE").
 * - heading: Main heading text.
 * - text: Subtext/description below the heading.
 * - initialValues: Object with Formik initial values.
 * - validationSchema: Yup schema for form validation.
 * - onSubmit: Function to handle form submission.
 * - renderForm (optional): A render prop that receives Formik props and returns JSX for a custom form.
 *                           If not provided, a default email field + submit button form is rendered.
 * - portraitSrc: Path for the portrait image.
 * - transformPortraitImg (optional): Transform style for the portrait image.
 * - logoMarkSrc: Path for the logo overlay.
 */
const ReusableCallToAction = ({
  inquiryType,
  heading,
  text,
  initialValues,
  validationSchema,
  onSubmit,
  renderForm,
  portraitSrc,
  transformPortraitImg,
  logoMarkSrc,
}) => {
  // Use translation from the common/quoteForm namespace
  const { t, ready, i18n } = useTranslation("common/quoteForm")
  if (!ready) return null

  // Merge inquiryType into the initial values if provided
  const combinedInitialValues = { ...initialValues, inquiryType: inquiryType || initialValues.inquiryType, language: i18n.language }

  return (
    <CtaSection id="cta">
      <CtaContainer>
        <LeftColumn>
          <CtaHeading>{heading || t("heading")}</CtaHeading>
          <CtaText>{text || t("text")}</CtaText>
          <Formik initialValues={combinedInitialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(formikProps) => (
              <>
                {renderForm ? (
                  renderForm(formikProps, t)
                ) : (
                  <>
                    <StyledForm>
                      <Field as={EmailField} type="email" name="email" placeholder={t("placeholders.email")} />
                      <SubmitButton type="submit" disabled={formikProps.isSubmitting}>
                        {t("submitButton")}
                      </SubmitButton>
                    </StyledForm>
                    <ErrorMessage name="email" component={StyledErrorMessage} />
                  </>
                )}
              </>
            )}
          </Formik>
        </LeftColumn>
        <RightColumn>
          <PortraitImage src={portraitSrc} transformPortraitImg={transformPortraitImg} alt={t("alt.portrait")} />
          <LogoMark src={logoMarkSrc} alt={t("alt.logomark")} />
        </RightColumn>
      </CtaContainer>
    </CtaSection>
  )
}

export default ReusableCallToAction
