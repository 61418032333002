import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// Reusable components from the codebase
import ReusableHero from "@components/layout/Hero/ReusableHero"
// import ReusableCallToAction from "@components/layout/sections/ReusableCallToAction"
import { Heading } from "@components/base"

import IconBadge from "@assets/icons/Badge.svg"
import IconLeaf from "@assets/icons/Leaf.svg"
import IconCollaboration from "@assets/icons/Collaboration.svg"
import IconGender from "@assets/icons/Gender.svg"
import IconPassion from "@assets/icons/Passion.svg"
import IconCommitment from "@assets/icons/Commitment.svg"
import { GridRow } from "@components/layout/GridRow"
import Row from "@components/layout/Row"

import LinkedIn from "@assets/icons/LinkedIn.svg"

// ------------------------------------------------------------------
// Basic Layout & Section Wrappers (reuse or extend as needed)
// ------------------------------------------------------------------
export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
`

const Section = styled.section`
  width: 100%;
  padding: 60px 20px;
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : "none")};
  background-size: cover;
  background-position: center center;
  position: relative;

  @media (min-width: 768px) {
    padding: 80px 40px;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const SectionSubtitle = styled.h3`
  font-size: 1.6rem;
  margin: 1.5rem 0 1rem;
  color: ${({ theme }) => theme?.primary || "#666"};
  text-align: center;
  font-weight: 500;
`

const Paragraph = styled.p`
  font-size: 1.125rem;
  line-height: 1.7;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: ${({ center }) => (center ? "center" : "left")};
`

// ------------------------------------------------------------------
// Custom Grids & Cards for Missions, Engagements & Team
// ------------------------------------------------------------------
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`

const Card = styled.div`
  background: #fff;
  background: ${({ theme }) => theme?.tertiary || "#fff"};
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`

const CardTitle = styled.h3.attrs(() => ({
  className: "gradient-text",
}))`
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme?.white || "#007BFF"};
`

const CardText = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #bbbbbb;
`

// ------------------------------------------------------------------
// Team Section Styles
// ------------------------------------------------------------------
const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`

const TeamMember = styled.div`
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
`

const TeamPhoto = styled.img`
  width: 100%;
  max-width: 300px;
  height: 300px;
  border-radius: 0 0 12px 12px;
  object-fit: cover;
  position: relative;
  z-index: 1;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
`

const TeamName = styled.h4`
  font-size: 1.5rem;
  margin: 1rem 0 0.25rem;
  color: ${({ theme }) => theme?.primary || "#007BFF"};
`

const TeamRole = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme?.tertiary || "#333"};
  font-weight: 500;
  max-width: 380px;
  margin: 0 auto;
`

const TeamBio = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  padding: 0 1rem;
  margin-top: 0.5rem;
`

const ImgLogos = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 100px;
  object-fit: contain;
  display: flex;
  height: auto;
  margin: 0 auto;
  margin-bottom: 1.15rem;
`

// Logo container
const Logo = styled.img`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;

  /* svg { */
  max-width: 250px;
  width: 100%;
  height: auto;
  max-height: 100px;
  margin: 0;
  transition: width 0.3s ease;
  margin: 0 auto;
  margin-bottom: 1rem;
  /* } */
`

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  background: linear-gradient(
    -182deg,
    ${(props) => props.theme.secondary}40,
    ${(props) => props.theme.tertiary},
    ${(props) => props.theme.tertiary},
    ${(props) => props.theme.secondary}10
  );
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 2px dashed ${(props) => props.theme.primary};
  }

  svg,
  path {
    width: 45px;
    height: 45px;
    fill: ${(props) => props.theme.white};
    color: ${(props) => props.theme.white};
  }
`

const Icon = ({ IconBase, color = "#333" }: { IconBase: React.ReactNode; color?: string }) => {
  return <IconWrapper>{IconBase}</IconWrapper>
}

const LinkedInIconWrapper = styled.div`
  margin-left: 0.2rem;
  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme?.tertiary || "#007BFF"};
  }
`

// ------------------------------------------------------------------
// AboutUsPage Component
// ------------------------------------------------------------------
export const AboutUsPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("about")

  return (
    <>
      <Helmet>
        <title>{t("pageTitle")}</title>
        <meta name="description" content={t("pageDescription")} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Layout>
        {/* ----------------------------
            HERO SECTION
        ----------------------------- */}
        <ReusableHero
          headingPre={t("hero.headingPre")}
          headingHighlight={t("hero.headingHighlight")}
          headingPost={t("hero.headingPost")}
          subtitle={t("hero.subtitle")}
          imageSrc="/img/moving-hero.png"
          imageAlt={t("hero.imageAlt")}
          ctaLabel={t("hero.ctaLabel")}
          ctaOnClick={() => navigate("/contact")}
        />

        {/* ----------------------------
            OUR HISTORY SECTION
        ----------------------------- */}
        <Section>
          <Container>
            <Heading level={2}>{t("ourHistory.title")}</Heading>
            <Paragraph>{t("ourHistory.text1")}</Paragraph>
            <Paragraph>{t("ourHistory.text2")}</Paragraph>
            <Paragraph>{t("ourHistory.text3")}</Paragraph>
          </Container>
        </Section>

        {/* ----------------------------
            OUR MISSIONS SECTION
        ----------------------------- */}
        <Section bgColor="#f9f9f9">
          <Container>
            <Heading level={2}>{t("ourMissions.title")}</Heading>
            <Grid>
              <Card>
                <Logo src="/img/Logo-white-Brisson-3pl.svg" alt="Brisson 3PL" />

                <CardText>{t("ourMissions.cards.brisson3pl.text")}</CardText>
              </Card>
              <Card>
                <Logo src="/img/Logo-white-Brisson-Moving.svg" alt="Brisson Moving" />
                <CardText>{t("ourMissions.cards.brissonMoving.text")}</CardText>
              </Card>
              <Card>
                <Logo src="/img/Logo-white-Art-Solution.svg" alt="Art Solution" />
                <CardText>{t("ourMissions.cards.artSolutions.text")}</CardText>
              </Card>
            </Grid>
          </Container>
        </Section>

        {/* ----------------------------
            OUR COMMITMENTS SECTION
        ----------------------------- */}
        <Section>
          <Container>
            <Heading level={2}>{t("ourCommitments.title")}</Heading>
            <Grid>
              <Card>
                <Icon IconBase={<IconBadge />} />
                <CardTitle>{t("ourCommitments.cards.professionalism.title")}</CardTitle>
                <CardText>{t("ourCommitments.cards.professionalism.text")}</CardText>
              </Card>
              <Card>
                <Icon IconBase={<IconLeaf />} />
                <CardTitle>{t("ourCommitments.cards.environmentalAwareness.title")}</CardTitle>
                <CardText>{t("ourCommitments.cards.environmentalAwareness.text")}</CardText>
              </Card>
              <Card>
                <Icon IconBase={<IconCollaboration />} />
                <CardTitle>{t("ourCommitments.cards.collaboration.title")}</CardTitle>
                <CardText>{t("ourCommitments.cards.collaboration.text")}</CardText>
              </Card>
              <Card>
                <Icon IconBase={<IconGender />} />
                <CardTitle>{t("ourCommitments.cards.inclusion.title")}</CardTitle>
                <CardText>{t("ourCommitments.cards.inclusion.text")}</CardText>
              </Card>
              <Card>
                <Icon IconBase={<IconPassion />} />
                <CardTitle>{t("ourCommitments.cards.passion.title")}</CardTitle>
                <CardText>{t("ourCommitments.cards.passion.text")}</CardText>
              </Card>
              <Card>
                <Icon IconBase={<IconCommitment />} />
                <CardTitle>{t("ourCommitments.cards.commitment.title")}</CardTitle>
                <CardText>{t("ourCommitments.cards.commitment.text")}</CardText>
              </Card>
            </Grid>
          </Container>
        </Section>

        {/* ----------------------------
            TEAM SECTION
        ----------------------------- */}
        <Section bgColor="#f5f5f5">
          <Container>
            <Heading level={2}>{t("team.title")}</Heading>
            <TeamGrid>
              <TeamMember>
                <TeamPhoto src="/img/chloe-daneau-presidente-art-solution-brisson-demenagement-brisson-3pl.jpg" alt="Chloé Daneau" />
                <Row width="fit-content" align="center" justify="center" margin="0.6rem auto">
                  {" "}
                  <TeamName>{t("team.members.chloe.name")}</TeamName>
                  <a href="https://ca.linkedin.com/in/chlo%C3%A9-daneau-mba-8a39b417/en" target="_blank" rel="noopener noreferrer">
                    <LinkedInIconWrapper>
                      <LinkedIn alt="LinkedIn" />
                    </LinkedInIconWrapper>
                  </a>
                </Row>
                <TeamRole>{t("team.members.chloe.role")}</TeamRole>
                <TeamBio>{t("team.members.chloe.bio")}</TeamBio>
              </TeamMember>
              <TeamMember>
                <TeamPhoto src="/img/linda-directrice-art-solution-brisson-demenagement.jpg" alt="Linda Comeau" />
                <TeamName>{t("team.members.linda.name")}</TeamName>
                <TeamRole>{t("team.members.linda.role")}</TeamRole>
                <TeamBio>{t("team.members.linda.bio")}</TeamBio>
              </TeamMember>
            </TeamGrid>
          </Container>
        </Section>

        <Section bgColor="#fff">
          <Container>
            <Heading level={2} center>
              {t("commitments.title")}
            </Heading>
            <br />
            <TeamGrid>
              <div>
                <ImgLogos src="/img/logo-conductrice-de-camion.png" alt="Truck Drivers Program" />
                <Paragraph center>{t("commitments.truckDriversProgram")}</Paragraph>
              </div>
              <div>
                <ImgLogos src="/img/logo-symbiose-cci-laval.jpg" alt="Symbiose CCI Laval" />
                <Paragraph center>{t("commitments.symbioseLaval")}</Paragraph>
              </div>
              <div>
                <ImgLogos src="/img/logo-fondation-des-jeunes.png" alt="Fondation des jeunes" />
                <Paragraph center>{t("commitments.storageAndInternships")}</Paragraph>
              </div>
            </TeamGrid>
            <GridRow col="2" mobile>
              <div>
                <ImgLogos src="/img/we-connect-logo.png" alt="We Connect" />
                <Paragraph center>{t("commitments.weConnect")}</Paragraph>
              </div>
              <div>
                <ImgLogos src="/img/women-owned-logo.png" alt="Women Owned" />
                <Paragraph center>{t("commitments.womenOwned")}</Paragraph>
              </div>
            </GridRow>
          </Container>
        </Section>
      </Layout>
    </>
  )
}

export default AboutUsPage
