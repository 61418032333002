import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik"
import * as Yup from "yup"
import api from "@utils/api.client" // Replace with your real API endpoint
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// ==================================
// 1) STYLED COMPONENTS & ANIMATIONS
// ==================================
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const SubmissionContainer = styled.section`
  width: 100%;
  min-height: 90vh;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  position: relative;
`

const SubmissionHeading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`

const SubmissionSubheading = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
`

const StyledForm = styled(FormikForm)`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`

// Instead of styling Formik's Field directly, we style the native element.
const InputField = styled.input`
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme?.primary || "#666"};
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.black || "#333"};
  color: ${({ theme }) => theme?.secondary || "#fff"};
`

const StyledSelect = styled.select`
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme?.primary || "#666"};
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.black || "#333"};
  color: ${({ theme }) => theme?.secondary || "#fff"};
`

const TextArea = styled.textarea`
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme?.primary || "#666"};
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.black || "#333"};
  color: ${({ theme }) => theme?.secondary || "#fff"};
  resize: vertical;
`

const StyledErrorMessage = styled.div`
  color: #ff6b6b;
  font-size: 0.9rem;
`

const SubmitButton = styled.button`
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: ${({ theme }) => theme?.primary || "#d11117"};
  border: 1px solid ${({ theme }) => theme?.primary || "#d11117"};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    opacity: 0.9;
  }
`

// Success banner
const SuccessBanner = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  animation: ${fadeIn} 0.5s ease-out;
  z-index: 2;
`

// Error banner
const ErrorBanner = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #ff6b6b;
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 1rem;
  animation: ${fadeIn} 0.5s ease-out;
  z-index: 2;
`

// ==================================
// 2) MAIN COMPONENT
// ==================================
const SubmissionPage = () => {
  const { t, i18n } = useTranslation("submission")
  const navigate = useNavigate()

  // Banner states
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  // Fixed inquiry type
  const fixedInquiryType = "GENERAL_QUOTE"

  // Initial form values
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    service: "",
    message: "",
    inquiryType: fixedInquiryType,
    language: i18n.language,
  }

  // Validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required(t("validation.nameRequired")),
    email: Yup.string().email(t("validation.emailInvalid")).required(t("validation.emailRequired")),
    phoneNumber: Yup.string()
      .required(t("validation.phoneRequired"))
      .matches(/^\d{10}$/, t("validation.phoneInvalid")),
    service: Yup.string().required(t("validation.serviceRequired")),
    message: Yup.string().required(t("validation.messageRequired")),
  })

  // Submission handler
  const handleSubmit = async (values, actions) => {
    try {
      // Post to your real endpoint
      const response = await api.post("/inquiries/quote", values)
      console.log("Form submitted successfully:", response)
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(t("successAlert"))
        actions.resetForm()
        // Hide success banner after 3 seconds (or navigate if needed)
        navigate(t("cta.successRedirectUrl"))
      }
    } catch (error) {
      console.error("Error submitting the form:", error)
      setErrorMessage(t("errorAlert"))
      // Hide error banner after 3 seconds
      setTimeout(() => setErrorMessage(""), 3000)
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <SubmissionContainer>
      {successMessage && <SuccessBanner>{successMessage}</SuccessBanner>}
      {errorMessage && <ErrorBanner>{errorMessage}</ErrorBanner>}

      <SubmissionHeading>{t("heading")}</SubmissionHeading>
      <SubmissionSubheading>{t("subheading")}</SubmissionSubheading>

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(formikProps) => (
          <StyledForm>
            {/* Hidden fields */}
            <Field type="hidden" name="inquiryType" value={fixedInquiryType} />
            <Field type="hidden" name="language" value={i18n.language} />

            {/* Name Field */}
            <Label htmlFor="name">{t("fields.name")}</Label>
            <Field as={InputField} id="name" name="name" placeholder={t("placeholders.name")} />
            <ErrorMessage name="name" component={StyledErrorMessage} />

            {/* Email Field */}
            <Label htmlFor="email">{t("fields.email")}</Label>
            <Field as={InputField} id="email" name="email" placeholder={t("placeholders.email")} />
            <ErrorMessage name="email" component={StyledErrorMessage} />

            {/* Phone Number Field */}
            <Label htmlFor="phoneNumber">{t("fields.phoneNumber")}</Label>
            <Field as={InputField} id="phoneNumber" name="phoneNumber" placeholder={t("placeholders.phoneNumber")} />
            <ErrorMessage name="phoneNumber" component={StyledErrorMessage} />

            <Label htmlFor="service">{t("fields.service")}</Label>
            <Field as={StyledSelect} id="service" name="service">
              <option value="">{t("dropdown.default")}</option>
              <option value="moving">{t("dropdown.moving")}</option>
              <option value="3pl">{t("dropdown.3pl")}</option>
              <option value="artTransport">{t("dropdown.artTransport")}</option>
            </Field>
            <ErrorMessage name="service" component={StyledErrorMessage} />

            {/* Message Field */}
            <Label htmlFor="message">{t("fields.message")}</Label>
            <Field as={TextArea} id="message" name="message" placeholder={t("placeholders.message")} />
            <ErrorMessage name="message" component={StyledErrorMessage} />

            {/* Submit Button */}
            <SubmitButton type="submit" disabled={formikProps.isSubmitting}>
              {t("submitButton")}
            </SubmitButton>
          </StyledForm>
        )}
      </Formik>
    </SubmissionContainer>
  )
}

export default SubmissionPage
