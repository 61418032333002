import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const ThankYouContainer = styled.section`
  width: 100%;
  min-height: 90vh;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
`

const ThankYouHeading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`

const ThankYouText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
`

const ThankYouPage = () => {
  const { t } = useTranslation("submission")
  return (
    <ThankYouContainer>
      <ThankYouHeading>{t("thankYou.heading")}</ThankYouHeading>
      <ThankYouText>{t("thankYou.text")}</ThankYouText>
    </ThankYouContainer>
  )
}

export default ThankYouPage
