import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import useAuthStore from "../../features/auth/authStore.client"

// Base URL from environment variables, fallback to local dev endpoint
const API_BASE_URL = process.env.NODE_ENV === "production" ? `${import.meta.env.VITE_APP_URL}/api` : "/api"

// Error structure for consistent error handling
interface ApiError {
  message: string
  statusCode?: number
  details?: Record<string, unknown>
}

// Extend AxiosResponse for API typing
export interface ApiResponse<T = unknown> extends AxiosResponse {
  data: T
}

// Variables to manage concurrent token refreshes
let isRefreshing = false
const failedQueue: Array<{
  resolve: (value?: unknown) => void
  reject: (reason?: unknown) => void
}> = []

function addToFailedQueue(resolve: (value?: unknown) => void, reject: (reason?: unknown) => void) {
  failedQueue.push({ resolve, reject })
}

function processQueue(error: unknown = null) {
  if (error) {
    failedQueue.forEach(({ reject }) => reject(error))
  } else {
    failedQueue.forEach(({ resolve }) => resolve())
  }
  failedQueue.length = 0 // Clear the queue
}

// Create an Axios instance
function createAxiosInstance(): AxiosInstance {
  const instance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true, // Ensures cookies are sent with requests
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
    },
  })

  setupInterceptors(instance)
  return instance
}

function setupInterceptors(instance: AxiosInstance) {
  // Request interceptor can be used to add extra headers if needed
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  )

  // Response interceptor handles 401 errors and refreshes token
  instance.interceptors.response.use(
    (response) => response,
    async (error: unknown) => {
      const { refreshAccessToken, logout, setError } = useAuthStore.getState()
      const typedError = error as {
        config?: AxiosRequestConfig & { _retry?: boolean }
        response?: { status: number; data?: any }
      }
      const originalRequest = typedError.config

      // If there's no response (network error), reject immediately.
      if (!typedError.response) {
        return Promise.reject(error)
      }

      // Bypass interceptor for public endpoints like /auth/login or /auth/logout
      if (originalRequest?.url?.includes("/auth/login") || originalRequest?.url?.includes("/auth/logout")) {
        return Promise.reject(error)
      }

      // Check for 401 Unauthorized.
      if (typedError.response.status === 401) {
        // Prevent infinite loops for /auth/refresh.
        if (originalRequest?.url?.includes("/auth/refresh") || originalRequest?._retry) {
          logout()
          return Promise.reject(error)
        }

        // If a token refresh is already in progress, queue this request.
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            addToFailedQueue(resolve, reject)
          }).then(() => {
            if (originalRequest) {
              return instance(originalRequest)
            }
            return Promise.reject(new Error("Original request is undefined"))
          })
        }

        // Begin the refresh flow.
        isRefreshing = true
        try {
          await refreshAccessToken()
          processQueue() // Resolve all queued requests.
          if (originalRequest) {
            originalRequest._retry = true
            return instance(originalRequest)
          }
          return Promise.reject(new Error("Original request is undefined"))
        } catch (refreshError) {
          processQueue(refreshError)
          logout()
          return Promise.reject(refreshError)
        } finally {
          isRefreshing = false
        }
      }

      // For all other errors, format and reject.
      const formattedError = formatError(error)
      setError(formattedError.message)
      return Promise.reject(formattedError)
    },
  )
}

// Format error to a consistent structure
function formatError(error: unknown): ApiError {
  const typedError = error as {
    response?: { data?: { message?: string }; status?: number }
    message?: string
  }
  return {
    message: typedError.response?.data?.message || typedError.message || "An unexpected error occurred",
    statusCode: typedError.response?.status,
    details: typedError.response?.data || {},
  }
}

// Create the axios instance
const apiInstance = createAxiosInstance()

// Expose typed wrappers for get/post/put/delete
export const api = {
  get: async <T,>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    const query = params ? `?${new URLSearchParams(params as Record<string, string>).toString()}` : ""
    return apiInstance.get<T>(`${url}${query}`, config)
  },

  post: async <T,>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    try {
      return await apiInstance.post<T>(url, data, config)
    } catch (error: any) {
      console.error(`Error in POST request to ${url}:`, error)
      throw formatError(error)
    }
  },

  put: async <T,>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    return apiInstance.put<T>(url, data, config)
  },

  delete: async <T,>(url: string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    return apiInstance.delete<T>(url, config)
  },
}

export default api
