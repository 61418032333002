import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import { useTranslation } from "react-i18next"

// Fade in animation (fade + slight translation)
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

// Main container for the section
const StatsSection = styled.section`
  width: 100%;
  min-height: 60vh;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-evenly;
  align-items: center;
  animation: ${fadeIn} 0.8s ease forwards;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

// Individual stat item (number + description)
const StatItem = styled.div`
  text-align: center;
  margin: 0 20px;
`

// Emphasized number styling
const StatNumber = styled.h2`
  display: flex;
  font-size: 4.4rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.primary};
  background: linear-gradient(-182deg, ${(props) => props.theme.tertiary}, ${(props) => props.theme.primary}, ${(props) => props.theme.primary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`

// Description text styling
const StatDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin: 0;
`

// Custom hook to detect when an element is in view
function useInView(ref: React.RefObject<HTMLElement>, options = {}) {
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true)
          observer.disconnect()
        }
      },
      { threshold: 0.1, ...options },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [ref, options])

  return isInView
}

// Component to animate numbers
function AnimatedNumber({ value, plusSign, metric }: { value: number; plusSign?: boolean; metric?: string }) {
  const [displayValue, setDisplayValue] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const isInView = useInView(ref)

  useEffect(() => {
    if (isInView) {
      let start = 0
      const duration = 1000 // Animation duration in ms
      const increment = value / (duration / 16) // Increment per frame (assuming ~60fps)

      const animate = () => {
        start += increment
        if (start >= value) {
          setDisplayValue(value)
        } else {
          setDisplayValue(Math.floor(start))
          requestAnimationFrame(animate)
        }
      }

      animate()
    }
  }, [isInView, value])

  return (
    <div ref={ref}>
      {plusSign && "+"}
      {displayValue}
      {metric && ` ${metric}`}
    </div>
  )
}

function KeyStats() {
  const { t } = useTranslation("home/keyStats")
  return (
    <StatsSection>
      <StatItem>
        <StatNumber>
          <AnimatedNumber plusSign value={t("stat1.number")} />
        </StatNumber>
        <StatDescription>{t("stat1.description")}</StatDescription>
      </StatItem>
      <StatItem>
        <StatNumber>
          <AnimatedNumber plusSign value={t("stat2.number")} />
          &nbsp; {t("stat2.metric")}
        </StatNumber>
        <StatDescription>{t("stat2.description")}</StatDescription>
      </StatItem>
      <StatItem>
        <StatNumber>
          <AnimatedNumber value={t("stat3.number")} />
          &nbsp; {t("stat3.metric")}
        </StatNumber>
        <StatDescription>{t("stat3.description")}</StatDescription>
      </StatItem>
    </StatsSection>
  )
}

export default KeyStats
