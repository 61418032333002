import styled, { keyframes } from "styled-components"
// -------------------------------------
// Animations
// -------------------------------------
export const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

// -------------------------------------
// Styled Components
// -------------------------------------

// Outer header container
export const HeaderLayout = styled.header`
  position: sticky;
  top: 0;
  z-index: 9999; /* Ensure it stays on top */
  width: 100%;
  height: 13vh;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); */
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Optional: for smooth transitions if needed */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
`

// Logo container
export const Logo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 20px;

  /* If you have a primary color in your theme, you could use it here. */
  color: ${({ theme }) => theme?.primary || "#000"};

  svg {
    max-width: 220px;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }

  &:hover,
  &:focus {
    /* Slight enlarge on hover/focus, optional */
    svg {
      /* transform: scale(1.03); */
    }
  }

  @media (max-width: 992px) {
    padding: 0;

    svg {
      max-width: 200px;
    }
  }
`

// Standard desktop nav container
export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    display: none;
  }
`

// Individual link styling
export const NavLink = styled.a`
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  color: ${({ theme }) => theme?.textPrimary || "#333"};
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme?.primary || "#ff5349"};
    outline: none;
  }
`

// A phone number link to keep consistent styling with NavLink
export const PhoneLink = styled.a`
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  margin-right: 1.5rem;
  color: ${({ theme }) => theme?.textPrimary || "#333"};
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme?.primary || "#ff5349"};
    outline: none;
  }
`

// Large call-to-action button on the right
// using a slight gradient to replicate or improve the screenshot style
export const CTAButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;

  /* Gradient or solid color for a modern look */
  background: linear-gradient(135deg, #ff5f6d 0%, #ffc371 100%);
  border-radius: 0.8rem;
  /* For a simpler single color, you could use theme.primary or #D50000, etc. */
  /* background-color: ${({ theme }) => theme?.primary || "#D50000"}; */

  padding: 0.5rem 20px;

  transition: background 0.3s ease;

  &:hover,
  &:focus {
    background: linear-gradient(135deg, #ff5f6d 10%, #ff9966 90%);
    outline: none;
  }

  @media (max-width: 992px) {
    display: none;
  }
`

// Hamburger icon for mobile
export const Hamburger = styled.button`
  display: none;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0 20px;

    outline: none;

    &:focus {
      /* outline: 2px solid ${({ theme }) => theme?.primary || "#D50000"}; */
      /* outline-offset: 4px; */
    }
  }
`

// The three lines inside the hamburger
export const HamburgerBar = styled.div<{ open: boolean; index: number }>`
  width: 25px;
  height: 3px;
  background-color: ${({ theme }) => theme?.textPrimary || "#333"};
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;

  /* Rotate or hide bars based on open state */
  ${({ open, index }) =>
    open && index === 0
      ? "transform: rotate(45deg) translate(5px, 6px);"
      : open && index === 1
        ? "opacity: 0;"
        : open && index === 2
          ? "transform: rotate(-45deg) translate(5px, -6px);"
          : ""}
`

// Mobile menu container
export const MobileMenu = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px; /* just below the header */
  right: 20px;
  background-color: ${({ theme }) => theme?.backgroundPrimary || "#fff"};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px;
  animation: ${slideDown} 0.3s ease forwards;
  z-index: 9998;

  /* If not open, hide entirely (except we rely on a conditional render for smoothness) */
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-10px)")};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    visibility 0.3s ease;

  @media (min-width: 993px) {
    display: none;
  }

  /* Links inside mobile menu */
  a {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    padding: 10px 0;
    color: ${({ theme }) => theme?.textPrimary || "#333"};
    text-decoration: none;
    border-bottom: 1px solid #eee;
    transition: color 0.2s ease;

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &:focus {
      color: ${({ theme }) => theme?.primary || "#ff5349"};
      outline: none;
    }
  }
`
