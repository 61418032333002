import React from "react"
import styled, { css } from "styled-components"

/**
 * Variants for the Text component.
 */
export type TextVariant = "body" | "caption" | "overline"

/**
 * Props for the Text component.
 */
export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  /**
   * The text variant determines styling.
   */
  variant?: TextVariant
  /**
   * Optionally override the default font size (in rem or other CSS units).
   */
  customFontSize?: string
  /**
   * The text content.
   */
  children: React.ReactNode
}

const variantStyles = {
  body: css`
    font-size: ${({ theme }) => theme.typography.fontSize.md};
    line-height: 1.6;
  `,
  caption: css`
    font-size: ${({ theme }) => theme.typography.fontSize.sm};
    line-height: 1.4;
    color: ${({ theme }) => theme.secondaryText};
  `,
  overline: css`
    font-size: ${({ theme }) => theme.typography.fontSize.xs};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: ${({ theme }) => theme.secondaryText};
  `,
}

const StyledText = styled.p<TextProps>`
  margin: 0;
  padding: 0;
  /* font-family: ${({ theme }) => theme.typography.fontFamily}; */
  color: ${({ theme }) => theme.primaryText};
  ${({ variant = "body", customFontSize }) => (customFontSize ? `font-size: ${customFontSize};` : variantStyles[variant])}
`

export const Text: React.FC<TextProps> = ({ variant = "body", children, ...rest }) => {
  return (
    <StyledText variant={variant} {...rest}>
      {children}
    </StyledText>
  )
}

export default Text
