import React from "react"
import styled, { css } from "styled-components"

/**
 * Variants for the Badge component.
 */
export type BadgeVariant = "primary" | "secondary" | "success" | "warning" | "danger"

/**
 * Props for the Badge component.
 */
export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * The badge variant controls its background and text colors.
   */
  variant?: BadgeVariant
  /**
   * Badge content.
   */
  children: React.ReactNode
}

const StyledBadge = styled.span<BadgeProps>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  ${({ variant, theme }) => {
    switch (variant) {
      case "primary":
        return css`
          background-color: ${theme.primary};
          color: ${theme.white};
        `
      case "secondary":
        return css`
          background-color: ${theme.secondary};
          color: ${theme.white};
        `
      case "success":
        return css`
          background-color: ${theme.success};
          color: ${theme.white};
        `
      case "warning":
        return css`
          background-color: ${theme.warning};
          color: ${theme.black};
        `
      case "danger":
        return css`
          background-color: ${theme.danger};
          color: ${theme.white};
        `
      default:
        return css`
          background-color: ${theme.grey};
          color: ${theme.white};
        `
    }
  }}
`

export const Badge: React.FC<BadgeProps> = ({ variant = "primary", children, ...props }) => {
  return (
    <StyledBadge variant={variant} {...props}>
      {children}
    </StyledBadge>
  )
}

export default Badge
