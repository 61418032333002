import { Heading as HeadingBase } from "@components/base"
import { GridRow } from "@components/layout/GridRow"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Section = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: white;
`

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
`

const Heading = styled(HeadingBase)`
  text-align: center;
  margin-bottom: 5rem;
`

const AccreditationItem = styled.div`
  flex: 0 1 300px;
  border-radius: 10px;
  padding: 30px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  }
`

const AccreditationTitle = styled.h3`
  font-size: 2.55rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: 500;
`

const AccreditationText = styled.p`
  font-size: 1.12rem;
  font-weight: normal;
  color: ${({ theme }) => theme?.tertiary || "#333"};
  margin-bottom: 0;
`

const AccreditationSeparator = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme?.primary || "#007BFF"};
  margin: 20px 0;
`

const Accreditations = () => {
  const { t, ready } = useTranslation("home/accreditations")

  if (!ready) return null

  // Retrieve the accreditation items array from translations
  const items = t("accreditations", { returnObjects: true })

  return (
    <Section>
      <Container>
        <Heading level={2}>{t("heading")}</Heading>
        <GridRow col="3" gap="40px" mobile>
          {Array.isArray(items) &&
            items.map((item, index) => (
              <AccreditationItem key={index}>
                <AccreditationTitle>{item.title}</AccreditationTitle>
                <AccreditationSeparator />
                <AccreditationText>{item.text}</AccreditationText>
              </AccreditationItem>
            ))}
        </GridRow>
      </Container>
    </Section>
  )
}

export default Accreditations
