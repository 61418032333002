import Homepage from "../pages/website/Home"
import About from "../pages/website/About"
import ContactForm from "../pages/website/GeneralContactForm"
import Brisson3Pl from "@pages/website/services/Brisson3PL"
import BrissonMoving from "@pages/website/services/BrissonMoving"
import ArtSolutions from "@pages/website/services/BrissonArtSolutions"
import SubmissionPage from "@pages/website/SubmissionPage"
import ThankYouPage from "@pages/website/SubmissionPage/ThankYouPage"

export const routesConfig = [
  {
    key: "home",
    // The homepage is assumed to be the index (empty slug)
    paths: { en: "", fr: "" },
    element: <Homepage />,
  },
  {
    key: "about",
    paths: { en: "about", fr: "a-propos" },
    element: <About />,
  },
  {
    key: "contact",
    paths: { en: "contact", fr: "contact" }, // if same in both languages
    element: <ContactForm />,
  },
  // Group 3 branches
  {
    key: "services-3pl",
    paths: { en: "services/outsourced-logistics-3pl", fr: "services/logistique-eternalisee-3pl" },
    element: <Brisson3Pl />,
  },
  {
    key: "services-moving",
    paths: { en: "services/commercial-and-industrial-moving", fr: "services/demenagement-commercial-et-industriel" },
    element: <BrissonMoving />,
  },
  {
    key: "services-art-transport",
    paths: { en: "services/art-transport", fr: "services/transport-oeuvres-d-art" },
    element: <ArtSolutions />,
  },
  {
    key: "submission",
    paths: { en: "request-a-quote", fr: "demande-de-soumission" },
    element: <SubmissionPage />,
  },
  {
    key: "thank-you",
    paths: { en: "thank-you", fr: "merci" },
    element: <ThankYouPage />,
  },
]
