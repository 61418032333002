import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  /* Reset box-sizing for all elements */
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  /* Root level variables (optional) */
  :root {
    /* Define custom CSS variables if needed */
  }
  
  /* Base styles for the body */
  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.primaryText};
    font-family: ${({ theme }) => theme.typography.fontFamily.body};
    transition: background-color 0.3s ease, color 0.3s ease;
    line-height: 1.6;
  }
  
  /* Typography for headers */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.typography.fontFamily.headings};
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
    line-height: 1.2;
    color: ${({ theme }) => theme.primaryText};
    margin: 0;

    font-family: 'DM Sans', sans-serif ;
  font-weight:300;

  font-optical-sizing: auto;
  font-style: normal;
  }

  /* Paragraph styling */
  p {
    font-family: ${({ theme }) => theme.typography.fontFamily.body}, serif;
    font-weight: 300;
    line-height: 1.5;
    color: ${({ theme }) => theme.primaryText};
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "GRAD" 0;
    margin: 0 0 1em;
  }

  /* Inherit base font styles for common elements */
  a, span, li, input, textarea, button {
    font-family: inherit;
    color: inherit;
  }

  /* Form element focus styles */
  form *:focus {
    outline: 2.5px solid ${({ theme }) => theme.primary};
  }

  /* Textarea base styling */
  textarea {
    font-family: ${({ theme }) => theme.typography.fontFamily.body};
    font-weight: normal;
  }

  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 1s ease-in;
  }


  .gradient-text1{
    color: ${(props) => props.theme.primary};
    background: linear-gradient(
      -182deg,
      ${(props) => props.theme.tertiary},
      ${(props) => props.theme.primary},
      ${(props) => props.theme.primary},
      ${(props) => props.theme.tertiary}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export default GlobalStyle
