import i18n from "i18next"
import HttpBackend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

// Define the supported languages
const supportedLanguages = ["en", "fr"]

if (typeof window !== "undefined") {
  // Get the URL segments while filtering out empty strings
  const segments = window.location.pathname.split("/").filter(Boolean)
  // The first segment is expected to be the language code
  let currentLang = segments[0]

  // If the first segment is not a supported language, default to "fr"
  if (!supportedLanguages.includes(currentLang)) {
    currentLang = "fr"
    // Rebuild the path by inserting the default language at the beginning
    const newPath = "/" + [currentLang, ...segments].join("/")
    // Update the URL without reloading the page
    window.history.replaceState(null, "", newPath)
  }
}

// After the URL has been updated (if needed), extract the language from the URL
const urlLang = (typeof window !== "undefined" && window.location.pathname.split("/")[1]) || "fr"
const initialLang = supportedLanguages.includes(urlLang) ? urlLang : "fr"

// Initialize i18next with the updated initial language and fallback language
i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: initialLang, // Force the initial language based on the URL
    fallbackLng: "fr", // Set fallback to French as default
    ns: ["home"],
    defaultNS: "home",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}/translation.json",
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
