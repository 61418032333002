import React from "react"
import styled from "styled-components"

const AppLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <AppLayoutWrapper>{children}</AppLayoutWrapper>
}

export default AppLayout
