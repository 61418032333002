import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import { DefaultTheme } from "styled-components"

import { buildTheme } from "./theme-settings"

interface ThemeState {
  theme: DefaultTheme
  themeName: "light" | "dark"
  toggleTheme: () => void
  setTheme: (themeName: "light" | "dark") => void
  setCustomTheme: (customColors: Partial<DefaultTheme>) => void
}

const useThemeStore = create<ThemeState>()(
  persist(
    (set, get) => ({
      // Initialize with "light" mode
      theme: buildTheme("light"),
      themeName: "light",

      // Toggle between light/dark using buildTheme
      toggleTheme: () => {
        const currentMode = get().themeName
        const newThemeName = currentMode === "light" ? "dark" : "light"

        set({
          theme: buildTheme(newThemeName),
          themeName: newThemeName,
        })
      },

      // Explicitly set light/dark
      setTheme: (themeName) =>
        set({
          theme: buildTheme(themeName),
          themeName,
        }),

      // Merge custom overrides into the current theme
      setCustomTheme: (customColors) => {
        const { themeName } = get()
        // Start with the base theme for the current mode
        const baseTheme = buildTheme(themeName)

        set({
          theme: {
            ...baseTheme,
            ...customColors,
          },
        })
      },
    }),
    {
      name: "theme-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useThemeStore
