import React from "react"
import styled from "styled-components"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SvgLogo from "../../../../app/assets/img/Logo-Black.svg"

// Footer Container
const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.textPrimary};
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  text-align: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
    padding: 3rem 4rem;
  }
`

// Logo container
const Logo = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;

  svg {
    max-width: 250px;
    width: 100%;
    height: auto;
    max-height: 100px;
    margin: 0;
    transition: width 0.3s ease;
  }
`

// Individual Footer Section
const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`

// Section Title
const FooterTitle = styled.h4`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryDark};
  margin-bottom: 1rem;
`

// Link Styles
const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 1rem;
  margin: 0.25rem 0;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
`

// Contact Information
const ContactInfo = styled.p`
  font-size: 1rem;
  margin: 0.5rem 0;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.backgroundSecondary};
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark};
  }
`

// Social Media Icons Container (optional)
const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  a {
    color: ${({ theme }) => theme.textPrimary};
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`

const usePhoneNumber = () => {
  const location = useLocation()
  const isArtTransportPage = location.pathname === "/en/services/art-transport" || location.pathname === "/fr/services/transport-oeuvres-d-art"
  return isArtTransportPage ? "450-973-9668" : "(450) 681-5115"
}

export { FooterWrapper, Logo, FooterSection, FooterTitle, FooterLink, ContactInfo, Button, SocialIcons, usePhoneNumber }

const Footnote = styled.p`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0.8rem 1rem;
  width: 100%;

  a {
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`

const Footer = () => {
  const { t } = useTranslation("common/footer")
  const yearDate = new Date().getFullYear()

  return (
    <>
      <FooterWrapper>
        {/* About Us Section */}
        <FooterSection>
          <Logo href="/">
            <SvgLogo aria-label={t("altLogo", "Company Logo")} />
          </Logo>
          <FooterTitle>{t("aboutUs", "À propos de nous")}</FooterTitle>
          <p>
            {t(
              "aboutDescription",
              "Le Groupe Brisson est une entreprise familiale spécialisée dans la gestion immobilière et la prestation de services de qualité.",
            )}
          </p>
        </FooterSection>

        {/* Quick Links Section */}
        <FooterSection>
          <FooterTitle>{t("quickLinks", "Liens Rapides")}</FooterTitle>
          <FooterLink to="/">{t("home", "Accueil")}</FooterLink>
          <FooterLink to={t("routes.ourExpertise", "Notre expertise")}>{t("services", "Services")}</FooterLink>
          <FooterLink to={t("routes.requestQuote")}>{t("requestQuote", "Demande de soumission")}</FooterLink>
          <FooterLink to={t("routes.requestQuote")}>{t("requestRental", "Demande d'entreposage")}</FooterLink>
        </FooterSection>

        {/* Contact Section */}
        <FooterSection>
          <FooterTitle>{t("contact", "Contact")}</FooterTitle>
          <ContactInfo>
            {t("phoneLabel", "Téléphone")}:
            <a
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              href={`tel:${usePhoneNumber()}`}
            >
              {" "}
              {usePhoneNumber()}
            </a>
          </ContactInfo>
          <ContactInfo style={{ marginBottom: "0.45rem" }}>
            {t("emailLabel", "Email")}:{" "}
            <a
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              href="mailto:info@legroupebrisson.com"
            >
              info@legroupebrisson.com
            </a>
          </ContactInfo>
          <ContactInfo style={{ marginBottom: "0.45rem" }}>
            {t("officeHours", "Heures d’ouverture : Bureaux : L-J 8h à 16h30, V 8h-15h30")}
          </ContactInfo>
          <ContactInfo style={{ marginBottom: "0.45rem" }}>{t("availability", "Disponible en tout temps pour les travaux")}</ContactInfo>
          <ContactInfo style={{ marginBottom: "0.45rem" }}>
            {t("contactAnytime", "Pour nous joindre en tout temps info@legroupebrisson.com")}
          </ContactInfo>
          <ContactInfo as="a" href={t("routes.contact", "/contact")}>
            <Button>{t("contactButton", "Nous contacter")}</Button>
          </ContactInfo>
          {/* Uncomment to include social icons if needed
          <SocialIcons>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in" />
            </a>
          </SocialIcons>
          */}
        </FooterSection>
      </FooterWrapper>
      <Footnote>
        © {yearDate} Le Groupe Brisson{" "}
        <a
          href="https://www.sor-seo.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={t("designCredit", "Visit SOR SEO for design, development and devshop agency services")}
        >
          | {t("design", "Design et développement par SOR SEO")}
        </a>
      </Footnote>
    </>
  )
}

export default Footer
