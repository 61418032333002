import styled from "styled-components"
import LogoMarkRef from "@assets/img/LogoMark.svg"

// ------------------------------------------------------------------
// Basic Layout & Section Wrappers
// ------------------------------------------------------------------

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
`

/**
 * A generic Section with optional background color or image.
 */
const Section = styled.section<{ bgColor?: string; bgImage?: string }>`
  width: 100%;
  padding: 60px 20px;
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : "none")};
  background-size: cover;
  background-position: center center;
  position: relative;

  @media (min-width: 768px) {
    padding: 80px 40px;
  }
`

// SECTION CONTAINER
const FeatureSection = styled.section`
  padding: 80px 20px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
`

// CONTENT CONTAINER
const FeatureContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

// SECTION HEADER
const SectionHeading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  color: #333;
`

const SectionSubheading = styled.p`
  text-align: center;
  font-size: 1.125rem;
  color: #555;
  margin-bottom: 2rem;
`

// GRID LAYOUT FOR FEATURE CARDS
const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    & > :last-child {
      grid-column: 1 / -1; /* Span full width */
    }
  }
`

// FEATURE CARD
const FeatureCard = styled.div`
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 2rem 1.5rem;
  text-align: center;
  overflow: hidden;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`

// CUSTOM SHAPE ELEMENT (DIAMOND)
const CustomShape = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  background: linear-gradient(135deg, ${({ theme }) => theme?.primary || "#007bff"}, ${({ theme }) => theme?.primaryDark || "#0056b3"});
  clip-path: polygon(50% 0%, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%);
  opacity: 0.15;
  z-index: 0;

  transition: clip-path 0.3s;
  &:hover {
    transition: clip-path 0.3s;

    clip-path: polygon(50% 0%, 90% 10%, 100% 50%, 90% 90%, 50% 100%, 10% 90%, 0% 50%, 10% 10%);
  }
`

// LOGO MARK (made smaller)
const LogoMark = styled(LogoMarkRef)`
  width: 7rem;
  height: 7rem;
  margin: 0 auto 1rem auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

// FEATURE TITLE
const FeatureTitle = styled.h3`
  font-size: 1.8rem;
  margin-top: 60px; /* Ensure space for the custom shape */
  margin-bottom: 1rem;
  color: ${({ theme }) => theme?.primary || "#007bff"};
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
`

// FEATURE DESCRIPTION
const FeatureDescription = styled.p`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  position: relative;
  z-index: 1;
`

// PROFESSIONAL FEATURE SECTION COMPONENT
// Data is expected to have keys: title, subheading, and feature objects: cost, flexibility, security, expertise.
export const ProfessionalFeatureSection = ({ data }) => {
  const features = [data.customMove, data.turnkeySolutions, data.operationalContinuity, data.smartInvestment]

  return (
    <FeatureSection>
      <FeatureContainer>
        <SectionHeading>{data.title}</SectionHeading>
        <SectionSubheading>{data.subheading}</SectionSubheading>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <CustomShape />
              <LogoMark />
              <FeatureTitle className="gradient-text1">{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.text}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeatureGrid>
      </FeatureContainer>
    </FeatureSection>
  )
}
