import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, Outlet, useParams } from "react-router-dom"

const LanguageWrapper = () => {
  const { lang } = useParams()
  const { i18n } = useTranslation()

  // Validate language. Only allow 'en' or 'fr'
  if (!["en", "fr"].includes(lang)) {
    return <Navigate to="/en" replace />
  }

  // Sync i18next language with the URL parameter and update HTML lang attribute
  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang)
    }
    document.documentElement.lang = lang
  }, [lang, i18n])

  // Render child routes so nested components receive updated route parameters.
  return <Outlet />
}

export default LanguageWrapper
