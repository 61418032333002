import React from "react"
import styled from "styled-components"
import LogoMarkRef from "@assets/img/LogoMark.svg"

// SECTION CONTAINER
const FeatureSection = styled.section`
  padding: 80px 20px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
`

// CONTENT CONTAINER
const FeatureContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

// SECTION HEADER
const SectionHeading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  color: #333;
`

const SectionSubheading = styled.p`
  text-align: center;
  font-size: 1.125rem;
  color: #555;
  margin-bottom: 2rem;
`

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 992px) {
    /* Create a 6-column grid */
    grid-template-columns: repeat(6, 1fr);

    /* First row: three items each taking 2 columns (33.33% each) */
    & > *:nth-child(1),
    & > *:nth-child(2),
    & > *:nth-child(3) {
      grid-column: span 2;
    }

    /* Second row: two items each taking 3 columns (50% each) */
    & > *:nth-child(4),
    & > *:nth-child(5) {
      grid-column: span 3;
    }
  }
`

export default FeatureGrid

// FEATURE CARD
const FeatureCard = styled.div`
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 2rem 1.5rem;
  text-align: center;
  overflow: hidden;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`

// CUSTOM SHAPE ELEMENT (DIAMOND)
const CustomShape = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  background: linear-gradient(135deg, ${({ theme }) => theme?.primary || "#007bff"}, ${({ theme }) => theme?.primaryDark || "#0056b3"});
  clip-path: polygon(50% 0%, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%);
  opacity: 0.15;
  z-index: 0;

  transition: clip-path 0.3s;
  &:hover {
    transition: clip-path 0.3s;

    clip-path: polygon(50% 0%, 90% 10%, 100% 50%, 90% 90%, 50% 100%, 10% 90%, 0% 50%, 10% 10%);
  }
`

// LOGO MARK (made smaller)
const LogoMark = styled(LogoMarkRef)`
  width: 7rem;
  height: 7rem;
  margin: 0 auto 1rem auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

// FEATURE TITLE
const FeatureTitle = styled.h3`
  font-size: 1.8rem;
  margin-top: 60px; /* Ensure space for the custom shape */
  margin-bottom: 1rem;
  color: ${({ theme }) => theme?.primary || "#007bff"};
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
`

// FEATURE DESCRIPTION
const FeatureDescription = styled.p`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  position: relative;
  z-index: 1;
`

// PROFESSIONAL FEATURE SECTION COMPONENT
// Data is expected to have keys: title, subheading, and feature objects: cost, flexibility, security, expertise.
export const ProfessionalFeatureSection = ({ data }) => {
  const features = [data.expertise, data.flexibility, data.security, data.network, data.installation]

  return (
    <FeatureSection>
      <FeatureContainer>
        <SectionHeading>{data.title}</SectionHeading>
        <SectionSubheading>{data.subheading}</SectionSubheading>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <CustomShape />
              <LogoMark />
              <FeatureTitle className="gradient-text1">{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.text}</FeatureDescription>
            </FeatureCard>
          ))}
        </FeatureGrid>
      </FeatureContainer>
    </FeatureSection>
  )
}
