import React from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import ReusableHero from "@components/layout/Hero/ReusableHero"
import ReusableServicesOverview from "@components/layout/sections/ReusableServicesOverview"

import ReusableCallToAction from "@components/layout/sections/ReusableCallToAction"
import FullScreenSliderSection from "@components/layout/sections/FullScreenSlider"
import { Layout, ModernStorageSection } from "../Brisson3PL"
import { getQuoteFormSchema, renderQuoteForm } from "@components/layout/sections/ReusableCallToAction/Quote"
import api from "@utils/api.client"
import { ProfessionalFeatureSection } from "./ProfessionalFeatureSection"

const BrissonMoving = () => {
  const { t, ready } = useTranslation("services/brissonMoving")
  const navigate = useNavigate()

  if (!ready) return <div />

  const servicesOverviewData = t("servicesOverview", { returnObjects: true })
  servicesOverviewData.cards = servicesOverviewData.cards.map((card: any) => ({
    ...card,
    logo: "/img/Logo-white-Brisson-Moving.svg",
  }))

  const whyChooseData = t("whyChoose", { returnObjects: true })
  const storageData = t("premiumStorage", { returnObjects: true })

  const movingSolutionsSlider = [t("solutions.factory"), t("solutions.commercial"), t("solutions.industrial"), t("solutions.storage")]

  const initialValues = { inquiryType: "MOVING", name: "", email: "", phoneNumber: "", message: "" }

  // Submission handler
  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post("/inquiries/moving", values)
      console.log(t("cta.submitted"), values)
      if (response.status === 200 || response.status === 201) {
        actions.resetForm()
        actions.setSubmitting(false)
        // Optionally navigate to a thank you page:
        navigate(t("cta.successRedirectUrl"))
      }
    } catch (error) {
      console.error("Error submitting moving inquiry:", error)
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <meta name="keywords" content={t("meta.keywords")} />
        <meta property="og:title" content={t("meta.ogTitle")} />
        <meta property="og:description" content={t("meta.ogDescription")} />
        <meta property="og:image" content={t("meta.ogImage")} />
        <meta property="og:url" content={t("meta.ogUrl")} />
      </Helmet>

      <Layout>
        <ReusableHero
          headingPre={t("hero.headingPre")}
          headingHighlight={t("hero.headingHighlight")}
          headingPost={t("hero.headingPost")}
          subtitle={t("hero.subtitle")}
          imageSrc="/img/moving-hero.png"
          imageAlt="Brisson Moving"
          ctaLabel={t("hero.ctaLabel")}
          ctaOnClick={() => navigate("/faire-une-demande")}
        />

        <ReusableServicesOverview bgImg="/img/services-overview-bg.jpg" hasOverlay cardsData={servicesOverviewData.cards} autoRotateInterval={5000} />

        <ProfessionalFeatureSection data={whyChooseData} />

        {/* <FullScreenSliderSection slides={movingSolutionsSlider} /> */}

        {/* <ModernStorageSection data={storageData} /> */}

        <ReusableCallToAction
          inquiryType="MOVING"
          heading={t("cta.heading")}
          text={t("cta.text")}
          initialValues={initialValues}
          validationSchema={getQuoteFormSchema(t)}
          onSubmit={handleSubmit}
          renderForm={(formikProps) => renderQuoteForm(formikProps, t)}
          portraitSrc="/img/cta-persona.png"
          logoMarkSrc="/img/LogoMark.svg"
        />
      </Layout>
    </>
  )
}

export default BrissonMoving
