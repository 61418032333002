import * as Yup from "yup"
import { Field, ErrorMessage, Form } from "formik"
import styled from "styled-components"

// Styled components for the quote form
const StyledForm = styled(Form)`
  display: grid;
  gap: 1rem;
  max-width: 500px;
  width: 100%;

  input {
    background: transparent;
    border: 1.5px solid ${({ theme }) => theme.primary};
    border-radius: 5px;
    height: 2rem;
    padding: 1rem 0.3rem;
    outline: none;
  }

  textarea {
    background: transparent;
    border: 1.5px solid ${({ theme }) => theme.primary};
    border-radius: 5px;
    padding: 0.5rem;
    outline: none;
  }
`

const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark};
  }
`

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 0.9rem;
`

/**
 * Returns a Yup validation schema for the quote form.
 * @param t - The translation function from useTranslation.
 * @returns A Yup schema.
 */
export const getQuoteFormSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("validation.nameRequired")),
    email: Yup.string().email(t("validation.emailInvalid")).required(t("validation.emailRequired")),
    phoneNumber: Yup.string()
      .required(t("validation.phoneRequired"))
      .matches(/^\d{10}$/, t("validation.phoneInvalid")),
    message: Yup.string().required(t("validation.messageRequired")),
  })

/**
 * Renders the quote form.
 * @param formikProps - Formik's formikProps.
 * @param t - The translation function from useTranslation.
 * @returns JSX for the form.
 */
export const renderQuoteForm = (formikProps, t) => (
  <>
    <StyledForm>
      <Field type="text" name="name" placeholder={t("placeholders.name")} />
      <Field type="email" name="email" placeholder={t("placeholders.email")} />
      <Field type="phone" name="phoneNumber" placeholder={t("placeholders.phone")} />
      <Field as="textarea" name="message" placeholder={t("placeholders.message")} />
      <SubmitButton type="submit" disabled={formikProps.isSubmitting}>
        {t("submitButton")}
      </SubmitButton>
    </StyledForm>
    <ErrorMessage name="name" component={StyledErrorMessage} />
    <ErrorMessage name="email" component={StyledErrorMessage} />
    <ErrorMessage name="phoneNumber" component={StyledErrorMessage} />
    <ErrorMessage name="message" component={StyledErrorMessage} />
  </>
)

export default renderQuoteForm
