import { hydrateRoot } from "react-dom/client"
import App from "./app"
import "./i18n"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import { Suspense } from "react"

declare global {
  interface Window {
    __INITIAL_DATA__?: {
      isAuthenticated?: boolean
      url?: string
    }
  }
}

const isAuthenticated = window.__INITIAL_DATA__?.isAuthenticated || false
const url = window.__INITIAL_DATA__?.url || "/"

// 1) Standard hydration for the main app HTML
hydrateRoot(
  document.getElementById("root")!,
  <HelmetProvider>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <App isAuthenticated={isAuthenticated} url={url} />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
)

// 2) Then, if we want partial hydration for a subcomponent
//    we can do so conditionally or always:
// partialHydrate()
