import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

/* -----------------------
   1) FRAMER MOTION VARIANTS
----------------------- */
const heroContainerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      duration: 0.6,
      ease: "easeOut",
    },
  },
}

const textColumnVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.8, ease: "easeOut" },
  },
}

const imageColumnVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.8, ease: "easeOut" },
  },
}

/* -----------------------
   2) STYLED COMPONENTS
----------------------- */
const HeroWrapper = styled.section`
  width: 100%;
  min-height: 90vh;
  position: relative;
  box-sizing: border-box;
  /* overflow-x: hidden; */
`

const HeroContainer = styled(motion.div)`
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: center;
  box-sizing: border-box;
  min-height: 90vh;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`

const TextColumn = styled(motion.div)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;

  @media (min-width: 992px) {
    padding: 0;
  }
`

const TextContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

const ImageColumn = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const HeroImage = styled(motion.img)`
  width: 100%;
  /* min-height: 90vh; */

  height: 100%;
  object-fit: cover;
  /* border-radius: 6px 0 0 6px; */
  /* box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); */
  filter: grayscale(100%);
  transition: filter 0.8s ease-in-out;

  &:hover {
    filter: grayscale(0%);
  }
`

const HeroHeading = styled.h1`
  color: ${({ theme }) => theme?.tertiary || "#000"};
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  @media (min-width: 576px) {
    font-size: 2.5rem;
  }
  @media (min-width: 992px) {
    font-size: 3.75rem;
  }
`

const Highlight = styled.span`
  background-color: ${({ theme }) => theme?.tertiary || "#000"};
  color: ${({ theme }) => theme?.secondary || "#fff"};
  padding: 0 0.8rem;
  border-radius: 1rem;
`

const HeroSubtitle = styled.div`
  color: ${({ theme }) => theme?.primaryText || "#666"};
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 2rem;

  ul {
    width: 100%;
    padding: 0.1rem 0.2rem;
  }

  @media (min-width: 576px) {
    font-size: 1.125rem;

    ul {
      width: 100%;
      padding: 0.1rem 0.4rem;
    }
  }
`

export const Hero = () => {
  const { t } = useTranslation("home/hero")
  return (
    <HeroWrapper>
      <HeroContainer variants={heroContainerVariants} initial="hidden" animate="visible">
        {/* LEFT COLUMN: Text */}
        <TextColumn variants={textColumnVariants}>
          <TextContent>
            <HeroHeading>
              {t("heading.line1")}
              <br />
              {t("heading.line2")}
              <br />
              <Highlight>{t("heading.highlight")}</Highlight>
            </HeroHeading>
            <HeroSubtitle>
              <ul>
                <li>
                  <strong>{t("subtitle.bullet1")}</strong>
                  <br />
                  {t("subtitle.subbullet1")}
                </li>
                <li>
                  <strong>{t("subtitle.bullet2")}</strong>
                  <br />
                  {t("subtitle.subbullet2")}
                </li>
                <li>
                  <strong>{t("subtitle.bullet3")}</strong>
                  <br />
                  {t("subtitle.subbullet3")}
                </li>
              </ul>
            </HeroSubtitle>
          </TextContent>
        </TextColumn>

        {/* RIGHT COLUMN: Image */}
        <ImageColumn variants={imageColumnVariants}>
          <HeroImage src="/img/hero-montage-home.png" alt={t("imageAlt")} />
        </ImageColumn>
      </HeroContainer>
    </HeroWrapper>
  )
}

export default Hero
