import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

// Extend the Window interface to include gtag
declare global {
  interface Window {
    gtag?: (...args: any[]) => void
    dataLayer?: any[]
    __CSP_NONCE__?: string
  }
}

import styled from "styled-components"

const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [, setConsent] = useState<boolean | null>(null)

  const { t } = useTranslation("common/cookiePopup")

  useEffect(() => {
    const savedConsent = document.cookie
      .split("; ")
      .find((row) => row.startsWith("cookie_consent="))
      ?.split("=")[1]

    if (savedConsent === "true") {
      setConsent(true)
    } else if (savedConsent === "false") {
      setConsent(false)
    } else {
      setShowPopup(true)
    }
  }, [])

  const acceptCookies = () => {
    document.cookie = "cookie_consent=true; path=/; max-age=" + 60 * 60 * 24 * 365 + "; SameSite=Lax"
    setConsent(true)
    setShowPopup(false)
    loadGoogleAnalytics()
  }

  const declineCookies = () => {
    document.cookie = "cookie_consent=false; path=/; max-age=" + 60 * 60 * 24 * 365 + "; SameSite=Lax"
    setConsent(false)
    setShowPopup(false)
  }

  function loadGoogleAnalytics() {
    if (!window.gtag) {
      const script = document.createElement("script")
      // Read the nonce from the global variable injected by the server
      const nonce = window.__CSP_NONCE__
      if (nonce) {
        script.setAttribute("nonce", nonce)
      }
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-K5NTNBVB"
      script.async = true
      document.head.appendChild(script)

      script.onload = function () {
        window.dataLayer = window.dataLayer || []
        function gtag(...args: any[]) {
          window.dataLayer.push(args)
        }
        window.gtag = gtag
        gtag("js", new Date())
        gtag("config", "GTM-K5NTNBVB", { anonymize_ip: true })
      }
    }
  }

  // If user has already accepted or refused, do not display the popup
  if (!showPopup) return null

  return (
    <Overlay>
      <PopupContainer>
        {!showSettings ? (
          // FIRST SCREEN: Only "Paramètres" + "Accepter"
          <>
            <PopupTitle>{t("descriptionTitle", "Nous utilisons des cookies pour améliorer votre expérience")}</PopupTitle>
            <PopupText>
              {t(
                "description",
                "Pour offrir les meilleures expériences, nous utilisons des technologies telles que les cookies pour stocker et/ou accéder aux informations des appareils. Le fait de consentir à ces technologies nous permettra de traiter des données telles que le comportement de navigation ou les ID uniques sur ce site.",
              )}
            </PopupText>
            <ButtonGroup>
              <ParamButton onClick={() => setShowSettings(true)}>{t("params", "Paramètres")}</ParamButton>
              <AcceptButton onClick={acceptCookies}>{t("accept", "Accepter")}</AcceptButton>
            </ButtonGroup>
          </>
        ) : (
          // SECOND SCREEN: "Refuser" + "Accepter"
          <>
            <PopupTitle>{t("paramTitle", "Paramètres des cookies")}</PopupTitle>
            <PopupText>
              {t(
                "paramDescription",
                "Vous pouvez refuser ou accepter l'utilisation de cookies pour un meilleur ciblage publicitaire. Pour plus de détails, veuillez consulter notre politique de confidentialité.",
              )}
            </PopupText>
            <ButtonGroup>
              <RefuseButton onClick={declineCookies}>{t("decline", "Refuser")}</RefuseButton>
              <AcceptButton onClick={acceptCookies}>{t("accept", "Accepter")}</AcceptButton>
            </ButtonGroup>
          </>
        )}
      </PopupContainer>
    </Overlay>
  )
}

// === STYLED COMPONENTS ===

// Overlay that darkens the background and centers the pop-up
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PopupContainer = styled.div`
  background: ${({ theme }) => theme.tertiary}80;
  backdrop-filter: blur(5px);
  color: ${({ theme }) => theme.tertiary};
  width: 90%;
  max-width: 550px;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
`

const PopupTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 12px;
  color: white;
  font-size: 18px;
  text-align: center;
`

const PopupText = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
  color: white;
  line-height: 1.4;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const BaseButton = styled.button`
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`

const AcceptButton = styled(BaseButton)`
  background: ${({ theme }) => theme.tertiary};

  &:hover {
    background: rgb(71, 177, 114);
  }
`

const ParamButton = styled(BaseButton)`
  background: none;
  /* border: 2px solid ${({ theme }) => theme.white}; */

  &:hover {
    /* background: ${({ theme }) => theme.tertiary}10; */
  }
`

const RefuseButton = styled(BaseButton)`
  background: none;
  /* background: ${({ theme }) => theme.tertiary}50; */
  border: 2px solid ${({ theme }) => theme.white};

  &:hover {
    background: ${({ theme }) => theme.tertiary}10;
  }
`

export default CookiePopup
