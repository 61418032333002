import React, { JSX } from "react"
import styled, { css } from "styled-components"

/**
 * Props for the Heading component.
 */
export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The heading level (1–6) determines the rendered HTML tag and default styling.
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6
  /**
   * Optionally override the default font size (should be specified in rem or other CSS units).
   */
  customFontSize?: string
  /**
   * The heading content.
   */
  children: React.ReactNode
  /**
   * Additional CSS class names.
   */
  className?: string
}

const getResponsiveFontSize = (level: number, customFontSize: string | undefined, theme: any) => {
  if (customFontSize) return customFontSize
  switch (level) {
    case 1:
      return { desktop: theme.typography.fontSize.xxl, mobile: "2.35rem" }
    case 2:
      return { desktop: theme.typography.fontSize.xl, mobile: "2.2rem" }
    case 3:
      return { desktop: theme.typography.fontSize.lg, mobile: "1.75rem" }
    case 4:
      return { desktop: theme.typography.fontSize.md, mobile: "1.5rem" }
    case 5:
      return { desktop: theme.typography.fontSize.sm, mobile: "0.95rem" }
    case 6:
    default:
      return { desktop: theme.typography.fontSize.xs, mobile: "0.75rem" }
  }
}

const StyledHeading = styled.h1<HeadingProps>`
  margin: 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium || 700};
  color: ${({ theme }) => theme.primaryText};
  ${({ level = 1, customFontSize, theme }) => {
    const { desktop, mobile } = getResponsiveFontSize(level, customFontSize, theme)
    return css`
      font-size: ${desktop};
      @media (max-width: 768px) {
        font-size: ${mobile};
      }
    `
  }}
  text-align: ${({ center }) => (center ? "center" : "left")};
  line-height: 1.2;
`

/**
 * Heading is a flexible typography component for rendering headings (h1–h6).
 * It uses theme tokens by default, but you can override the font size via customFontSize.
 *
 * @example
 * <Heading level={2}>This is a Heading Level 2</Heading>
 * <Heading level={3} customFontSize="2.5rem">Custom Sized Heading</Heading>
 */
export const Heading: React.FC<HeadingProps> = ({ level = 1, children, ...rest }) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements
  return (
    <StyledHeading as={Tag} level={level} {...rest}>
      {children}
    </StyledHeading>
  )
}

export default Heading
