// -----------------------------
// Section 6: Modern Premium Storage Section

import { Heading } from "@components/base"
import styled from "styled-components"

// -----------------------------
const StorageSection = styled.section`
  padding: 60px 20px;
  background-color: #f5f5f5;
`

const StorageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const StorageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const StorageCard = styled.div`
  background: #fff;
  background: ${({ theme }) => theme?.tertiary || "#fff"};

  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
`

const StorageImage = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
`

const StorageContent = styled.div`
  padding: 1rem 1.5rem;
`

const StorageTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: white;
`

const StorageText = styled.p`
  font-size: 1rem;
  color: #b7b7b7;
`

export const ModernStorageSection = ({ data }) => {
  // data is expected to be an object with keys: title, subheading, and storage cards: docks, climate, inventory, vip.
  const cards = [data.climate, data.vip]
  return (
    <StorageSection>
      <StorageContainer>
        <Heading level={2} center>
          {data.title}
        </Heading>
        <p style={{ textAlign: "center", fontSize: "1.125rem", color: "#555" }}>{data.subheading}</p>
        <StorageGrid>
          {cards.map((card, index) => (
            <StorageCard key={index}>
              <StorageImage src={card.image} alt={card.title} />
              <StorageContent>
                <StorageTitle className="">{card.title}</StorageTitle>
                <StorageText>{card.text}</StorageText>
              </StorageContent>
            </StorageCard>
          ))}
        </StorageGrid>
      </StorageContainer>
    </StorageSection>
  )
}
