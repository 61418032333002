import React from "react"
import styled, { keyframes } from "styled-components"
import { NavLink } from "react-router-dom"

// Keyframe animations remain unchanged
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  16.67% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  66.67% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const glowAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
`

const FancyButton = styled.button`
  border: none;
  border-radius: 0.75rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  outline: none;
  position: relative;
  overflow: hidden;
  background: linear-gradient(270deg, ${({ theme }) => theme.primary}, ${({ theme }) => theme.tertiary}, ${({ theme }) => theme.primary});
  background-size: 600% 600%;
  animation: ${gradientAnimation} 60s ease-in-out 5s infinite;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(255, 107, 107, 0.6);
  }

  &:hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: radial-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    animation: ${glowAnimation} 6s infinite;
    pointer-events: none;
    z-index: 1;
  }

  span {
    position: relative;
    z-index: 2;
  }
`

const DelayedFancyButton = ({ children, to, href, ...rest }) => {
  if (to) {
    return (
      <NavLink
        to={to}
        {...rest}
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <FancyButton as="div">
          <span>{children}</span>
        </FancyButton>
      </NavLink>
    )
  }

  if (href) {
    return (
      <a href={href} {...rest}>
        <FancyButton as="div">
          <span>{children}</span>
        </FancyButton>
      </a>
    )
  }

  return (
    <FancyButton {...rest}>
      <span>{children}</span>
    </FancyButton>
  )
}

export default DelayedFancyButton
