import React from "react"
import {
  // BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { ThemeProvider } from "styled-components"

import routes, { routesConfig } from "./routes"
import useThemeStore from "./theme/useThemeStore"
import { GlobalStyle } from "./theme"

import AppLayout from "../components/layout/AppLayout"
import { ScrollToTop } from "../components/utils/scrollToTop"

import CookiePopup from "@components/base/CookieConsent"
import Header from "@pages/website/Common/Header"
import LanguageWrapper from "@utils/language/LanguageWrapper.client"
import Footer from "@pages/website/Common/Footer"

export interface AppProps {
  isAuthenticated: boolean
  url: string
  // Optionally pass a custom Router component (e.g. StaticRouter for SSR)
  Router?: React.ComponentType<{ children: React.ReactNode }>
  // Optionally pass router-specific props (for SSR, e.g., location, context)
  routerProps?: Record<string, unknown>
}

export const App: React.FC<AppProps> = ({
  isAuthenticated,
  // url,
  // Router = BrowserRouter,
  // routerProps = {},
}) => {
  const { theme } = useThemeStore()
  // console.log("App isAuthenticated", isAuthenticated)

  const languages = ["en", "fr"]
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <CookiePopup />
      <ScrollToTop />

      <Header />

      <AppLayout>
        <Routes>
          <Route path="/" element={<Navigate to="/en" replace />} />
          <Route path="/:lang" element={<LanguageWrapper />} />
          {languages.map((lang) =>
            routesConfig.map((route) => {
              const localizedPath = route.paths[lang] // e.g., "about" or "a-propos"
                ? `/${lang}/${route.paths[lang]}`
                : `/${lang}` // For the home route
              return <Route key={`${lang}-${route.key}`} path={localizedPath} element={route.element} />
            }),
          )}
        </Routes>
      </AppLayout>
      <Footer />
      {/* </Router> */}
    </ThemeProvider>
  )
}

export default App
