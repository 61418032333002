import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

const HeroContainer = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: ${({ bgImg }) => (bgImg ? `url(${bgImg}) no-repeat center center/cover` : `url("/img/services-overview-bg.jpg")`)};
  background-position: -10% 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;

  @media (max-width: 768px) {
    background-position: 50% 50%;
    min-height: 100vh;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

const CardsWrapper = styled.div`
  position: relative;
  width: 65%;
  height: 65%;
  perspective: 2000px;
  overflow: visible;
  z-index: 3;

  @media (max-width: 768px) {
    width: 85%;
    height: 85%;
  }
`

const Card = styled(motion.div)`
  position: absolute;
  width: 100%;
  border-radius: 16px;
  padding: 2rem;
  color: #fff;
  background: rgba(95, 120, 127, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &.inactive > * {
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const BranchLogo = styled.img`
  width: auto;
  max-height: 70px;
  margin-bottom: 1rem;
  object-fit: contain;
  @media (max-width: 768px) {
    max-height: 40px;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 75%;
  }
`

const Title = styled.h2`
  font-size: 1.75rem;
  margin: 0 0 1rem 0;
  line-height: 1.3;
  font-weight: 500;
  span {
    color: ${(props) => props.theme.primary};
    background: linear-gradient(
      -182deg,
      ${(props) => props.theme.tertiary},
      ${(props) => props.theme.primary},
      ${(props) => props.theme.primary},
      ${(props) => props.theme.tertiary}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: 768px) {
    font-size: 2.85rem;
  }
`

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 2rem 0;
  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.45rem 1.25rem;
  border-radius: 0.8rem;
  /* background: linear-gradient(
    82deg,
    ${(props) => props.theme.tertiary},
    ${(props) => props.theme.primary},
    ${(props) => props.theme.primary},
    ${(props) => props.theme.tertiary}
  ); */
  background: ${(props) => props.theme.primary};
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  transition: background 0.3s ease;
  cursor: pointer;
  &:hover,
  &:focus {
    background: #ff3333;
    outline: none;
  }
`

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;
  transform: translateY(-3rem);
`

const Dot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${(props) => (props.active ? props.theme.primary : "#ddd")};
  margin: 0 6px;
  cursor: pointer;
  transition: background 0.3s;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
`

/* ─────────────────────────────
   FRAMER MOTION POSITIONING
──────────────────────────────── */
const getCardPosition = (index) => {
  switch (index) {
    case 0:
      return { x: "0%", y: "0%", scale: 1, zIndex: 3 }
    case 1:
      return { x: "10%", y: "10%", scale: 0.95, zIndex: 2 }
    case 2:
      return { x: "20%", y: "20%", scale: 0.9, zIndex: 1 }
    default:
      return { x: "30%", y: "30%", scale: 0.85, zIndex: 0 }
  }
}

const transition = { type: "spring", stiffness: 200, damping: 30 }

/* ─────────────────────────────
   REUSABLE SERVICES OVERVIEW COMPONENT
──────────────────────────────── */
const ReusableServicesOverview = ({ bgImg, hasOverlay, cardsData, autoRotateInterval = 5000 }) => {
  // Create an order array for indices – first element is the active card.
  const [order, setOrder] = useState(cardsData.map((_, i) => i))
  const autoTimerRef = useRef(null)
  const handleScrollToCTA = (e) => {
    e.preventDefault()
    const target = document.getElementById("cta")
    if (target) {
      const offset = 5 * parseFloat(getComputedStyle(document.documentElement).fontSize) // Convert 5rem to px
      const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - offset
      window.scrollTo({ top: targetPosition, behavior: "smooth" })
    }
  }

  // Auto-rotate cards every autoRotateInterval ms.
  useEffect(() => {
    autoTimerRef.current = setInterval(() => {
      setOrder((prev) => [prev[1], prev[2], prev[0]])
    }, autoRotateInterval)
    return () => clearInterval(autoTimerRef.current)
  }, [autoRotateInterval])

  // Rotate to the next slide when the active card is clicked.
  const handleCardClick = (clickedIndex) => {
    if (clickedIndex === order[0]) {
      setOrder((prev) => [prev[1], prev[2], prev[0]])
      clearInterval(autoTimerRef.current)
    }
  }

  // When a dot is clicked, reorder so that the corresponding card becomes active.
  const handleDotClick = (targetIndex) => {
    const total = cardsData.length
    const newOrder = [targetIndex, (targetIndex + 1) % total, (targetIndex + 2) % total]
    setOrder(newOrder)
    clearInterval(autoTimerRef.current)
  }

  return (
    <>
      <HeroContainer bgImg={bgImg} hasOverlay={hasOverlay}>
        <CardsWrapper>
          <AnimatePresence>
            {order.map((cardIndex, positionInStack) => {
              const cardData = cardsData[cardIndex]
              const { logo, title, highlight, text, cta, link, logoAlt } = cardData
              const { x, y, scale, zIndex } = getCardPosition(positionInStack)
              const isInactive = positionInStack !== 0
              return (
                <Card
                  key={cardIndex}
                  className={isInactive ? "inactive" : ""}
                  style={{ transformStyle: "preserve-3d" }}
                  initial={{ x: "100%", y: "0%", opacity: 0, scale: 0.8 }}
                  animate={{ x, y, scale, opacity: 1, zIndex }}
                  exit={{
                    x: "-100%",
                    opacity: 0,
                    transition: { duration: 0.4, ease: "easeInOut" },
                  }}
                  transition={{
                    x: {
                      duration: 0.4,
                      ease: "easeInOut",
                      delay: isInactive ? 0.2 : 0,
                    },
                    y: {
                      duration: 0.2,
                      ease: "easeInOut",
                      delay: isInactive ? 0.2 : 0,
                    },
                  }}
                  onClick={() => handleCardClick(cardIndex)}
                >
                  <BranchLogo src={logo} alt={logoAlt || `Logo for ${title}`} />
                  <ContentContainer>
                    <Title>
                      {title} <br />
                      <span>{highlight}</span>
                    </Title>
                    <Description>{text}</Description>
                    <CTAButton onClick={handleScrollToCTA}>{cta}</CTAButton>
                  </ContentContainer>
                </Card>
              )
            })}
          </AnimatePresence>
        </CardsWrapper>
        {hasOverlay && <Overlay />}
      </HeroContainer>
      <DotsContainer>
        {cardsData.map((_, index) => (
          <Dot key={index} active={order[0] === index} onClick={() => handleDotClick(index)} />
        ))}
      </DotsContainer>
    </>
  )
}

export default ReusableServicesOverview
