import React from "react"
import styled from "styled-components"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "@utils/api.client"

// ================== STYLED COMPONENTS ================== //

// Overall container with a dark background
const CtaSection = styled.section`
  width: 100%;
  height: 90vh;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  overflow: hidden;

  @media (max-width: 900px) {
    height: auto;
    padding: 40px 20px;
  }
`

// Constrain content to a max width, using flex for side-by-side
const CtaContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`

// Left side: text + form
const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin-left: 2rem;
  @media (max-width: 900px) {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }
`

// The main heading, large and bold
const CtaHeading = styled.h2`
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
  line-height: 1.2;
  color: #fff;

  @media (min-width: 1200px) {
    font-size: 3rem;
  }
`

// A short paragraph below the heading
const CtaText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: #ccc;
`

// Form container styled as a row (input + button on the same line)
const StyledForm = styled(Form)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;

  @media (max-width: 900px) {
    margin: 0 auto;
  }
`

// The text input for email
const EmailField = styled(Field)`
  flex: 1;
  padding: 12px 16px;
  font-size: 1rem;
  border: 1.5px solid ${({ theme }) => theme.primary};
  outline: none;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.secondary};

  @media (max-width: 900px) {
    height: 65px;
    font-size: 1.2rem;
  }
`

// The submit button
const SubmitButton = styled.button`
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: ${({ theme }) => theme.primary};
  border: 1.5px solid ${({ theme }) => theme.primary};
  outline: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  transition: background-color 0.3s ease;

  &:hover {
    /* background-color: ${({ theme }) => theme.primaryDark};
       border: 1.5px solid ${({ theme }) => theme.primaryDark}; */
  }

  @media (max-width: 900px) {
    width: 100%;
    flex: 0.5;
  }
`

// Error message styling
const StyledErrorMessage = styled.div`
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 8px;
`

// Right side: black-and-white image with a red logomark overlay
const RightColumn = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 40px;
    max-width: 400px;
  }
`

// The black-and-white portrait
const PortraitImage = styled.img`
  width: 100%;
  max-width: 35vw;
  transform: translateY(32vh);
  height: auto;
  object-fit: cover;
  border: none;
  border-radius: 4px;
  filter: grayscale(100%);

  @media (max-width: 900px) {
    /* transform: translateY(10vh); */
    max-width: 100%;
    transform: translateY(0);
  }
`

// The red logomark at bottom-right
const LogoMark = styled.img`
  position: absolute;
  bottom: 14vh;
  right: 2rem;
  width: 180px;
  height: auto;
  transform: translate(25%, 25%);
`

// ================== FORM VALIDATION SCHEMA ================== //

const getCTAFormSchema = (t) =>
  Yup.object().shape({
    email: Yup.string().email(t("cta.validation.emailInvalid")).required(t("cta.validation.emailRequired")),
    language: Yup.string().required(t("cta.validation.languageRequired")),
    inquiryType: Yup.string().required(t("cta.validation.inquiryTypeRequired")),
  })

// ================== MAIN COMPONENT ================== //

const CallToAction = () => {
  const { t, ready, i18n } = useTranslation("home/cta")
  const navigate = useNavigate()
  if (!ready) return null

  const CTAFormSchema = getCTAFormSchema(t)

  // Placeholder image & logomark paths
  const portraitSrc = "/img/cta-persona.png"
  const logoMarkSrc = "/img/LogoMark.svg"

  const fixedInquiryType = "HOMEPAGE_QUICK"

  const initialValues = {
    email: "",
    inquiryType: fixedInquiryType,
    language: i18n.language,
  }

  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post("/inquiries/homepage", values)
      console.log(t("cta.submitted"), values)
      if (response.status === 200 || response.status === 201) {
        actions.resetForm()
        actions.setSubmitting(false)
        // Optionally navigate to a thank you page:
        navigate(t("cta.successRedirectUrl"))
      }
    } catch (error) {
      console.error("Error submitting moving inquiry:", error)
      actions.setSubmitting(false)
    }
  }

  return (
    <CtaSection>
      <CtaContainer>
        {/* LEFT COLUMN: Text & Form */}
        <LeftColumn>
          <CtaHeading>{t("cta.heading")}</CtaHeading>
          <CtaText>{t("cta.text")}</CtaText>

          <Formik initialValues={initialValues} validationSchema={CTAFormSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <>
                <StyledForm>
                  <Field type="hidden" name="inquiryType" value={fixedInquiryType} />
                  <Field type="hidden" name="language" value={i18n.language} />

                  <EmailField type="email" name="email" placeholder={t("cta.placeholder.email")} />
                  <SubmitButton type="submit" disabled={isSubmitting}>
                    {t("cta.submitButton")}
                  </SubmitButton>
                </StyledForm>
                <ErrorMessage name="email" component={StyledErrorMessage} />
              </>
            )}
          </Formik>
        </LeftColumn>

        {/* RIGHT COLUMN: B&W Image + Red Logomark */}
        <RightColumn>
          <PortraitImage src={portraitSrc} alt={t("cta.alt.portrait")} />
          <LogoMark src={logoMarkSrc} alt={t("cta.alt.logomark")} />
        </RightColumn>
      </CtaContainer>
    </CtaSection>
  )
}

export default CallToAction
