import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const QuoteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
  }
`

const BigQuote = styled.span`
  font-size: 100px;
  font-weight: bold;
  color: #333;
  transform: translateY(-50px);

  @media (max-width: 768px) {
    transform: translateY(0);
    height: 100px;
  }
`

const QuoteText = styled.p`
  font-size: 24px;
  font-style: italic;
  color: #666;
  max-width: 800px;
  margin: 20px 0;
`

const Quote: React.FC = () => {
  const { t } = useTranslation("home/quote")
  return (
    <QuoteContainer>
      <BigQuote>“</BigQuote>
      <QuoteText>{t("text")}</QuoteText>
    </QuoteContainer>
  )
}

export default Quote
