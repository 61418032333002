// routeUtils.js
import { routesConfig } from "../../../app/routes"

export const getRouteKeyFromPath = (pathname, currentLang) => {
  // Remove trailing slash and split the pathname.
  const segments = pathname.replace(/\/$/, "").split("/").filter(Boolean)
  if (segments.length < 1) return "home"
  // Join all segments after the language segment.
  const routeSlug = segments.slice(1).join("/").toLowerCase()
  if (!routeSlug) return "home"
  const match = routesConfig.find((route) => route.paths[currentLang] && route.paths[currentLang].toLowerCase() === routeSlug)
  return match ? match.key : null
}

export const getLocalizedPath = (routeKey, targetLang) => {
  const route = routesConfig.find((r) => r.key === routeKey)
  if (route) {
    const slug = route.paths[targetLang]
    return `/${targetLang}${slug ? "/" + slug : ""}`
  }
  return `/${targetLang}`
}
