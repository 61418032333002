import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

/**
 * Main section container.
 * Using relative positioning so that the image and content can be positioned appropriately.
 */
const Section = styled.section`
  position: relative;
  width: 100%;
  min-height: 110vh; /* Adjust as needed for your design */
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: auto;
    padding: 1rem;
  }
`

/**
 * Truck image rendered as an <img> to support alt text.
 * It takes up most of the viewport width and height.
 */
const TruckImage = styled.img`
  position: relative;
  top: 0;

  width: 80vw;
  height: 80vh;
  margin-left: auto;
  object-fit: cover;
  filter: grayscale(100%);

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  transition: filter 0.8s ease-in-out;
  &:hover {
    filter: grayscale(0%);
    transition: filter 0.8s ease-in-out;
  }
`

const Container = styled.div`
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

/**
 * Content container with a semi-transparent background and blur effect.
 * Positioned absolutely to overlay the truck image.
 */
const ContentContainer = styled.div`
  position: absolute;
  bottom: -26vh;
  left: 7vw;
  color: #fff;
  background: rgba(95, 120, 127, 0.2);
  backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 55vw;

  @media (max-width: 768px) {
    position: static;
    margin-top: 1rem;
    width: 100%;
    max-width: 100%;
    left: 0;
    padding: 1.5rem;
  }
`

/**
 * Subheading styling (e.g., "60 YEARS OF EXPERIENCE").
 */
const Subheading = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  margin: 0 0 0.5rem;

  @media (max-width: 768px) {
    text-align: center;
  }
`

/**
 * Main title styling.
 */
const Title = styled.h2`
  font-size: 2.25rem;
  color: ${(props) => props.theme.white};
  margin: 0 0 1rem;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 1.75rem;
    text-align: center;
  }
`

/**
 * Paragraph styling describing the company history.
 */
const Paragraph = styled.p`
  font-size: 1.15rem;
  line-height: 1.6;
  color: ${(props) => props.theme.tertiary};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
  }
`

const OurHistory = () => {
  const { t } = useTranslation("home/ourHistory")
  return (
    <Section>
      <Container>
        <TruckImage src="/img/art-solution-art-transport.png" alt={t("truckImage.alt")} />
        <ContentContainer>
          <Subheading>{t("subheading")}</Subheading>
          <Title>{t("title")}</Title>
          <Paragraph>{t("paragraph")}</Paragraph>
        </ContentContainer>
      </Container>
    </Section>
  )
}

export default OurHistory
