import React, { useState, useRef, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import { useTranslation } from "react-i18next"
import { getRouteKeyFromPath, getLocalizedPath } from "./routeUtils" // your route utilities

const languages = [
  { code: "en", label: "English", flag: "🇺🇸" },
  { code: "fr", label: "Français", flag: "🇫🇷" },
]

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const SelectedButton = styled.button`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition:
    background 0.3s,
    box-shadow 0.3s;
  &:hover {
    background: #f9f9f9;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
`

const OptionsList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: ${fadeIn} 0.2s ease-out;
  z-index: 100;
`

const OptionItem = styled.li`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: #f0f0f0;
  }
`

const LanguageSwitcher = ({ onSwitch }) => {
  const navigate = useNavigate()
  const location = useLocation()
  // Use useTranslation to get the proper i18n instance
  const { i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const currentLang = i18n.language || "en"

  const changeLanguage = (newLang) => {
    if (newLang === currentLang) return

    // Use the promise-based API of changeLanguage
    i18n
      .changeLanguage(newLang)
      .then(() => {
        // Update HTML lang attribute for SEO
        document.documentElement.lang = newLang

        // Determine current route key from the URL
        const routeKey = getRouteKeyFromPath(location.pathname, currentLang)
        // Build new URL using the mapping from route key
        const newPath = routeKey ? getLocalizedPath(routeKey, newLang) : `/${newLang}`

        navigate(newPath, { replace: true })
        if (onSwitch) onSwitch()
      })
      .catch((err) => {
        console.error("Error changing language:", err)
      })

    setIsOpen(false)
  }

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  // Determine the current language object for display
  const currentLangObj = languages.find((l) => l.code === currentLang) || languages[0]

  return (
    <DropdownWrapper ref={dropdownRef}>
      <SelectedButton onClick={() => setIsOpen((prev) => !prev)}>
        <span>{currentLangObj.flag}</span>
        <span>{currentLangObj.label}</span>
      </SelectedButton>
      {isOpen && (
        <OptionsList>
          {languages.map(({ code, label, flag }) => (
            <OptionItem key={code} onClick={() => changeLanguage(code)}>
              <span>{flag}</span>
              <span>{label}</span>
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </DropdownWrapper>
  )
}

export default LanguageSwitcher
