import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Heading } from "@components/base"

const Section = styled.section`
  width: 100%;
  padding: 80px 0;
  background: white; /* Subtle light-gray background for contrast */
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

/* 
   A more eye-catching heading:
   - Larger font-size
   - Bold
   - Gradient text effect 
*/
// const Heading = styled.h2`
//   text-align: center;

//   color: ${({ theme }) => theme?.tertiary || "#000"};
//   font-weight: 500;
//   font-size: 3.05rem;
//   line-height: 1.2;

// `

const Subheading = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  max-width: 700px;
  margin: 2.5rem auto;
  margin-top: 1rem;
`

/*
   CaseStudy is now a card with a subtle shadow,
   rounded corners, and a hover lift effect.
*/
const CaseStudy = styled.article`
  background: ${({ theme }) => theme?.tertiary || "#fff"};
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.07);
  padding: 30px;
  margin-bottom: 30px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  }
`

/*
   Title styling:
   - Slightly larger font-size
   - Stronger weight
   - Uses theme.primary
*/
const CaseStudyTitle = styled.h3`
  color: ${(props) => props.theme.primary};
  font-weight: 500;
  font-size: 2.25rem;
  margin-bottom: 10px;
`

/*
   Subtitle styling:
   - Uses theme.secondary
   - Lighter weight
   - A bit smaller than the title
*/
const CaseStudySubTitle = styled.h4`
  color: ${(props) => props.theme.secondary};
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 20px;
`

/*
   Subheadings for "Challenge", "Solution", "Result":
   - Slightly bigger text
   - Uppercase for emphasis
   - Spacing adjustments
*/
const SectionSubheading = styled.h4`
  font-size: 0.95rem;
  color: ${(props) => props.theme.secondary};
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
`

/*
   Body text for each segment:
   - Comfortable line-height
   - Neutral gray
*/
const CaseStudyText = styled.p`
  color: #909090;
  line-height: 1.6;
  font-size: 1.15rem;
`

/*
   Quoted text with a left border highlight:
   - Slightly larger font
   - Italic
   - Subtle color
   - Border accent using theme.secondary
*/
const CaseStudyQuote = styled.blockquote`
  margin-top: 2rem;
  font-size: 1.35rem;
  font-style: italic;
  color: #ffffff;
  border-left: 4px solid ${(props) => props.theme.primary};
  padding-left: 1rem;

  @media (max-width: 768px) {
    /* font-size: 1.2rem; */

    width: 100%;
    margin: 0.2rem 0;
  }
`

const CaseStudies = () => {
  const { t } = useTranslation("home/caseStudies")

  return (
    <Section id="temoignages">
      <Container>
        <Heading level={2} center>
          {t("heading")}
        </Heading>

        <Subheading>{t("subheading")}</Subheading>

        {/* Case Study #1 */}
        <CaseStudy>
          <CaseStudyTitle>{t("case1.title")}</CaseStudyTitle>
          <CaseStudySubTitle>{t("case1.subtitle")}</CaseStudySubTitle>

          <SectionSubheading>{t("case1.challengeTitle")}</SectionSubheading>
          <CaseStudyText>{t("case1.challengeText")}</CaseStudyText>

          <SectionSubheading>{t("case1.solutionTitle")}</SectionSubheading>
          <CaseStudyText>{t("case1.solutionText")}</CaseStudyText>

          <SectionSubheading>{t("case1.resultTitle")}</SectionSubheading>
          <CaseStudyText>{t("case1.resultText")}</CaseStudyText>

          <CaseStudyQuote>{t("case1.quote")}</CaseStudyQuote>
        </CaseStudy>

        {/* Case Study #2 */}
        <CaseStudy>
          <CaseStudyTitle>{t("case2.title")}</CaseStudyTitle>
          <CaseStudySubTitle>{t("case2.subtitle")}</CaseStudySubTitle>

          <SectionSubheading>{t("case2.challengeTitle")}</SectionSubheading>
          <CaseStudyText>{t("case2.challengeText")}</CaseStudyText>

          <SectionSubheading>{t("case2.solutionTitle")}</SectionSubheading>
          <CaseStudyText>{t("case2.solutionText")}</CaseStudyText>

          <SectionSubheading>{t("case2.resultTitle")}</SectionSubheading>
          <CaseStudyText>{t("case2.resultText")}</CaseStudyText>

          <CaseStudyQuote>{t("case2.quote")}</CaseStudyQuote>
        </CaseStudy>

        {/* Case Study #3 */}
        <CaseStudy>
          <CaseStudyTitle>{t("case3.title")}</CaseStudyTitle>
          <CaseStudySubTitle>{t("case3.subtitle")}</CaseStudySubTitle>

          <SectionSubheading>{t("case3.challengeTitle")}</SectionSubheading>
          <CaseStudyText>{t("case3.challengeText")}</CaseStudyText>

          <SectionSubheading>{t("case3.solutionTitle")}</SectionSubheading>
          <CaseStudyText>{t("case3.solutionText")}</CaseStudyText>

          <SectionSubheading>{t("case3.resultTitle")}</SectionSubheading>
          <CaseStudyText>{t("case3.resultText")}</CaseStudyText>

          <CaseStudyQuote>{t("case3.quote")}</CaseStudyQuote>
        </CaseStudy>
      </Container>
    </Section>
  )
}

export default CaseStudies
