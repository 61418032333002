import styled from "styled-components"
import { Helmet } from "react-helmet-async"
import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Popup from "@components/layout/Popup"

import Hero from "./Hero"
import ServicesOverview from "./ServicesOverview"
import KeyStats from "./KeyStats"
import OurHistory from "./OurHistory"
import Quote from "./Quote"
import CaseStudies from "./CaseStudies"
import Testimonials from "./Testimonials"
import Accreditations from "./Accreditations"
import CallToAction from "./CallToAction"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
`

export const Homepage = () => {
  // Access your translation hook
  const { t } = useTranslation("home")

  const [isPopupVisible, setPopupVisible] = useState(false)
  const popupTriggered = useRef(false)
  const navigate = useNavigate()

  useEffect(() => {
    const handleScroll = () => {
      if (!popupTriggered.current && window.scrollY > window.innerHeight * 1.5) {
        setPopupVisible(true)
        popupTriggered.current = true
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <meta name="keywords" content={t("meta.keywords")} />
        <meta name="robots" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={t("meta.ogTitle")} />
        <meta property="og:description" content={t("meta.ogDescription")} />
        <meta property="og:image" content={t("meta.ogImage")} />
        <meta property="og:url" content={t("meta.ogUrl")} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("meta.ogLocale")} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("meta.twitterTitle")} />
        <meta name="twitter:description" content={t("meta.twitterDescription")} />
        <meta name="twitter:image" content={t("meta.twitterImage")} />
      </Helmet>

      <Layout>
        <Hero />
        <ServicesOverview />
        <KeyStats />
        <OurHistory />
        <Quote />
        <CaseStudies />

        <Testimonials />
        <Accreditations />
        <CallToAction />

        {/* {isPopupVisible && (
          <Popup
            title={t("popup.title")}
            text={t("popup.text")}
            buttonLabel={t("popup.buttonLabel")}
            onClose={() => setPopupVisible(false)}
            onClick={() => {
              setPopupVisible(false)
              navigate("/faire-une-demande")
            }}
            imageUrl="/img/interieur-maison-unite.jpg"
          />
        )} */}
      </Layout>
    </>
  )
}

export default Homepage
