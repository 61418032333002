import { useState } from "react"
import { useTranslation } from "react-i18next"
import Row from "../../../../components/layout/Row"
import { HeaderLayout, Logo, Nav, NavLink, PhoneLink, Hamburger, HamburgerBar, MobileMenu } from "./Style"
import SvgLogo from "../../../../app/assets/img/Logo-Black.svg"
import LanguageSwitcher from "@utils/language/LanguageSwitcher.client"
import DelayedFancyButton from "./ButtonGradient"

const ServicesDropdown = ({ mobile, closeMenu, t }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  const handleMouseEnter = () => {
    if (!mobile) {
      setOpen(true)
    }
  }

  const handleMouseLeave = () => {
    if (!mobile) {
      setOpen(false)
    }
  }

  // The main fix on mobile:
  // Use max-height for a slide-down effect.
  // This ensures no extra space is taken when closed.
  const dropdownStyle = {
    position: mobile ? "static" : "absolute",
    top: mobile ? "auto" : "100%",
    left: 0,
    background: "white",
    boxShadow: mobile ? "none" : "0 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    minWidth: "200px",
    // Animate the open/close for mobile
    maxHeight: open ? "400px" : "0",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
    padding: mobile ? "0" : "0",
  }

  const linkStyle = mobile ? { marginLeft: "20px", display: "block", padding: "8px 16px" } : { display: "block", padding: "8px 16px" }

  const services = [
    {
      route: t("routes.services.brissonMoving"),
      label: t("nav.services.brissonMoving"),
    },
    {
      route: t("routes.services.brissonLogistics"),
      label: t("nav.services.brissonLogistics"),
    },
    {
      route: t("routes.services.brissonArt"),
      label: t("nav.services.brissonArt"),
    },
  ]

  // Mobile version
  if (mobile) {
    return (
      <div>
        <NavLink as="div" onClick={toggleOpen} style={{ cursor: "pointer" }}>
          {t("nav.services.title")}
        </NavLink>
        <div style={dropdownStyle}>
          {services.map((service, index) => (
            <div
              key={service.route}
              style={{
                borderBottom: index !== services.length - 1 ? "1px solid #ccc" : "none",
              }}
            >
              <NavLink href={service.route} onClick={closeMenu} style={linkStyle}>
                {service.label}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    )
  }

  // Desktop version
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: "relative" }}>
      <NavLink as="div">{t("nav.services.title")}</NavLink>
      <div style={dropdownStyle}>
        {services.map((service, index) => (
          <div
            key={service.route}
            style={{
              borderBottom: index !== services.length - 1 ? "1px solid #ccc" : "none",
            }}
          >
            <NavLink href={service.route} onClick={closeMenu} style={linkStyle}>
              {service.label}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  )
}

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { t, ready } = useTranslation("common/header")
  if (!ready) return null

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  return (
    <HeaderLayout>
      {/* Logo */}
      <Logo href={t("routes.home")} aria-label={t("nav.homepage")}>
        <SvgLogo aria-label={t("alt.logo")} />
      </Logo>

      {/* Desktop */}
      <Row margin="0" marginLeft="auto" padding="0" width="fit-auto" height="100%" style={{ alignItems: "center" }}>
        <Nav aria-label={t("nav.primaryNavigation")}>
          <NavLink href={t("routes.home")} onClick={closeMenu}>
            {t("nav.home")}
          </NavLink>
          <NavLink href={t("routes.about")} onClick={closeMenu}>
            {t("nav.about")}
          </NavLink>
          <ServicesDropdown mobile={false} closeMenu={closeMenu} t={t} />
          <NavLink href={t("routes.contact")} onClick={closeMenu}>
            {t("nav.contact")}
          </NavLink>

          <LanguageSwitcher />
          <Row align="center" width="fit-content" margin="0" marginLeft="auto" padding="0" height="100%" style={{ alignItems: "center" }}>
            <PhoneLink href="tel:+14506815115">{t("nav.phone")}</PhoneLink>
            <DelayedFancyButton to={t("routes.requestQuote")}>{t("nav.requestQuote")}</DelayedFancyButton>
          </Row>
        </Nav>
      </Row>

      {/* Hamburger for Mobile */}
      <Hamburger onClick={toggleMenu} aria-label={t("nav.toggleMobileMenu")} aria-expanded={menuOpen}>
        {[0, 1, 2].map((index) => (
          <HamburgerBar key={index} open={menuOpen} index={index} />
        ))}
      </Hamburger>

      {/* Mobile Menu */}
      <MobileMenu open={menuOpen} role="menu">
        <NavLink href={t("routes.home")} onClick={closeMenu}>
          {t("nav.home")}
        </NavLink>
        <NavLink href={t("routes.about")} onClick={closeMenu}>
          {t("nav.about")}
        </NavLink>
        <ServicesDropdown mobile={true} closeMenu={closeMenu} t={t} />
        <NavLink href={t("routes.contact")} onClick={closeMenu}>
          {t("nav.contact")}
        </NavLink>
        <LanguageSwitcher currentLanguage="FR" onSwitch={closeMenu} />
        <NavLink href="tel:4506815115" onClick={closeMenu}>
          {t("nav.phone")}
        </NavLink>
        <NavLink href={t("routes.requestQuote")} onClick={closeMenu}>
          {t("nav.requestQuote")}
        </NavLink>
      </MobileMenu>
    </HeaderLayout>
  )
}

export default Header
