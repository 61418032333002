import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

/* 
  ─────────────────────────────────────────────────────────────────────────────
   1) SAMPLE LOGOS & DATA
      Replace these with your actual logo imports or paths.
─────────────────────────────────────────────────────────────────────────────
*/
const PlaceholderLogo1 = "/img/Logo-white-Brisson-3pl.svg"
const PlaceholderLogo2 = "/img/Logo-white-Brisson-Moving.svg"
const PlaceholderLogo3 = "/img/Logo-white-Art-Solution.svg"

const cardsData = [
  {
    logo: PlaceholderLogo1,
    title: "card1.title",
    highlight: "card1.highlight",
    text: "card1.text",
    cta: "card1.cta",
    logoAlt: "card1.logoAlt",
    link: "card1.link",
  },
  {
    logo: PlaceholderLogo2,
    title: "card2.title",
    highlight: "card2.highlight",
    text: "card2.text",
    cta: "card2.cta",
    logoAlt: "card2.logoAlt",
    link: "card2.link",
  },
  {
    logo: PlaceholderLogo3,
    title: "card3.title",
    highlight: "card3.highlight",
    text: "card3.text",
    cta: "card3.cta",
    logoAlt: "card3.logoAlt",
    link: "card3.link",
  },
]

/* 
  ─────────────────────────────────────────────────────────────────────────────
   2) STYLED COMPONENTS
─────────────────────────────────────────────────────────────────────────────
*/
const HeroContainer = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh; /* Full viewport height */
  margin: 0;
  padding: 0;
  background: url("/img/services-overview-bg.jpg");
  background-position: -10% 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const CardsWrapper = styled.div`
  position: relative;
  width: 65%;
  height: 65%; /* ~65vh */
  perspective: 2000px;
  overflow: visible;

  @media (max-width: 768px) {
    width: 90%;
  }
`

/**
 * A single card. Inactive cards hide their content so only their edge shows.
 */
const Card = styled(motion.div)`
  position: absolute;
  width: 100%;
  border-radius: 16px;
  padding: 2rem;
  color: #fff;
  background: rgba(95, 120, 127, 0.2); /* #5F787F at 20% opacity */
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  /* Hide content of inactive cards */
  &.inactive > * {
    opacity: 0;
    pointer-events: none;
  }
`

/**
 * The brand logo at the top of each card.
 */
const BranchLogo = styled.img`
  width: auto;
  max-height: 80px;
  margin-bottom: 1rem;
  object-fit: contain;

  @media (max-width: 768px) {
    max-height: 40px;
  }
`

const ContentContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }
`

const Title = styled.h2`
  font-size: 1.75rem;
  margin: 0 0 1rem 0;
  line-height: 1.3;
  font-weight: 500;

  span {
    color: ${(props) => props.theme.primary};
    background: linear-gradient(-182deg, ${(props) => props.theme.tertiary}, ${(props) => props.theme.primary}, ${(props) => props.theme.primary});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (min-width: 768px) {
    font-size: 2.85rem;
  }
`

/**
 * The descriptive paragraph.
 */
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 2rem 0;

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`

/**
 * The call-to-action button.
 */
const CTAButton = styled(NavLink)`
  display: inline-block;
  padding: 0.45rem 1.25rem;
  border-radius: 0.8rem;
  background: linear-gradient(
    82deg,
    ${(props) => props.theme.tertiary},
    ${(props) => props.theme.primary},
    ${(props) => props.theme.primary},
    ${(props) => props.theme.tertiary}
  );
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  transition: background 0.3s ease;

  &:hover,
  &:focus {
    background: #ff3333;
    outline: none;
  }
`

/**
 * Dots container for navigation.
 */
const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;
  transform: translateY(-3rem);
`

/**
 * Individual dot.
 */
const Dot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${(props) => (props.active ? props.theme.primary : "#ddd")};
  margin: 0 6px;
  cursor: pointer;
  transition: background 0.3s;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
`

/* 
  ─────────────────────────────────────────────────────────────────────────────
   3) FRAMER MOTION POSITIONING
      - Inactive cards are not skewed; they’re simply translated
        bottom-right so only their edge shows.
─────────────────────────────────────────────────────────────────────────────
*/
const getCardPosition = (index) => {
  switch (index) {
    case 0:
      // Front card: fully visible.
      return {
        x: "0%",
        y: "0%",
        scale: 1,
        zIndex: 3,
      }
    case 1:
      // Middle card: translated bottom-right.
      return {
        x: "10%",
        y: "10%",
        scale: 0.95,
        zIndex: 2,
      }
    case 2:
      // Back card: further translated bottom-right.
      return {
        x: "20%",
        y: "20%",
        scale: 0.9,
        zIndex: 1,
      }
    default:
      return {
        x: "30%",
        y: "30%",
        scale: 0.85,
        zIndex: 0,
      }
  }
}

const transition = {
  type: "spring",
  stiffness: 200,
  damping: 30,
}

/* 
  ─────────────────────────────────────────────────────────────────────────────
ServicesOverview
      - Full-screen background with 3 stacked cards (65% height)
      - Automatic rotation, clickable active card, and clickable dots.
─────────────────────────────────────────────────────────────────────────────
*/
const ServicesOverview = () => {
  const { t } = useTranslation("home/servicesOverview")
  // "order" holds a permutation of indices. order[0] is the active card.
  const [order, setOrder] = useState([0, 1, 2])
  const autoTimerRef = useRef(null)

  // Auto-rotate every 5 seconds.
  useEffect(() => {
    autoTimerRef.current = setInterval(() => {
      setOrder((prev) => [prev[1], prev[2], prev[0]])
    }, 5000)
    return () => clearInterval(autoTimerRef.current)
  }, [])

  // On active card click, rotate to next slide.
  const handleCardClick = (clickedIndex) => {
    if (clickedIndex === order[0]) {
      setOrder((prev) => [prev[1], prev[2], prev[0]])
      clearInterval(autoTimerRef.current)
    }
  }

  // On dot click, reorder so the clicked card becomes active.
  const handleDotClick = (targetIndex) => {
    const total = cardsData.length
    const newOrder = [targetIndex, (targetIndex + 1) % total, (targetIndex + 2) % total]
    setOrder(newOrder)
    clearInterval(autoTimerRef.current)
  }

  return (
    <>
      <HeroContainer>
        <CardsWrapper>
          <AnimatePresence>
            {order.map((cardIndex, positionInStack) => {
              const cardData = cardsData[cardIndex]
              const { logo, title, highlight, text, cta, link } = cardData
              const { x, y, scale, zIndex } = getCardPosition(positionInStack)
              const isInactive = positionInStack !== 0
              return (
                <Card
                  key={cardIndex}
                  className={isInactive ? "inactive" : ""}
                  style={{ transformStyle: "preserve-3d" }}
                  initial={{ x: "100%", y: "0%", opacity: 0, scale: 0.8 }}
                  animate={{ x, y, scale, opacity: 1, zIndex }}
                  exit={{
                    x: "-100%",
                    opacity: 0,
                    transition: { duration: 0.4, ease: "easeInOut" },
                  }}
                  transition={{
                    x: {
                      duration: 0.4,
                      ease: "easeInOut",
                      delay: isInactive ? 0.2 : 0,
                    },
                    y: {
                      duration: 0.2,
                      ease: "easeInOut",
                      delay: isInactive ? 0.2 : 0,
                    },
                  }}
                  onClick={() => handleCardClick(cardIndex)}
                >
                  <BranchLogo src={logo} alt={t(cardData.logoAlt)} />
                  <ContentContainer>
                    <Title>
                      {t(title)} <br />
                      <span>{t(highlight)}</span>
                    </Title>
                    <Description>{t(text)}</Description>
                    <CTAButton to={t(link)}>{t(cta)}</CTAButton>
                  </ContentContainer>
                </Card>
              )
            })}
          </AnimatePresence>
        </CardsWrapper>
      </HeroContainer>
      <DotsContainer>
        {cardsData.map((_, index) => (
          <Dot key={index} active={order[0] === index} onClick={() => handleDotClick(index)} />
        ))}
      </DotsContainer>
    </>
  )
}

export default ServicesOverview
