import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

/**
 * Option type for Dropdown.
 */
export interface DropdownOption {
  label: string
  value: string
}

/**
 * Props for the Dropdown component.
 * We omit the native onSelect from HTMLAttributes to avoid conflicts.
 */
export interface DropdownProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "onSelect"> {
  /**
   * Array of options.
   */
  options: DropdownOption[]
  /**
   * Placeholder text when no option is selected.
   */
  placeholder?: string
  /**
   * Callback when an option is selected.
   */
  onSelect?: (value: string) => void
}

const DropdownContainer = styled.div`
  position: relative;
  width: 200px;
`

const DropdownButton = styled.button`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: ${({ theme }) => theme.components.borderRadius.medium};
  background-color: ${({ theme }) => theme.white};
  text-align: left;
  cursor: pointer;
`

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: ${({ theme }) => theme.components.borderRadius.medium};
  background-color: ${({ theme }) => theme.white};
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const DropdownItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.lightGrey};
  }
`

/**
 * Dropdown is a custom, accessible dropdown component for selecting options.
 * It accepts an array of options, a placeholder, and an onSelect callback.
 *
 * @example
 * <Dropdown
 *   placeholder="Choose an option"
 *   options={[
 *     { label: 'Option 1', value: 'option1' },
 *     { label: 'Option 2', value: 'option2' },
 *   ]}
 *   onSelect={(value) => console.log(value)}
 * />
 */
export const Dropdown: React.FC<DropdownProps> = ({ options, placeholder = "Select an option", onSelect, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<string>("")
  const containerRef = useRef<HTMLDivElement>(null)

  const handleSelect = (value: string) => {
    setSelected(value)
    if (onSelect) onSelect(value)
    setIsOpen(false)
  }

  const toggleOpen = () => setIsOpen((prev) => !prev)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  return (
    <DropdownContainer ref={containerRef} {...rest}>
      <DropdownButton onClick={toggleOpen}>{selected || placeholder}</DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.map((opt) => (
            <DropdownItem key={opt.value} onClick={() => handleSelect(opt.value)}>
              {opt.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
