import { Heading } from "@components/base"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Section = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: #f9f9f9;
`

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
`

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 4rem;
`

const TestimonialCard = styled.blockquote`
  width: 100%;
  background-color: #fff;
  background: ${({ theme }) => theme?.tertiary || "#fff"};
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.07);
  padding: 30px;
  margin: 0;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  font-size: 1rem;
  line-height: 1.6;
  color: ${({ theme }) => theme?.secondary || "#000"};
  font-style: italic;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  }
`

const TestimonialAuthor = styled.cite`
  display: block;
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme?.secondary || "#000"};
`

const Separator = styled.div`
  width: 100%;
  height: 2.5px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme?.primary || "#007BFF"};
  margin: 10px 0;
`

const Testimonials = () => {
  const { t, ready } = useTranslation("home/testimonials")

  if (!ready) return null

  const testimonials = t("testimonials", { returnObjects: true })

  return (
    <Section>
      <Container>
        <Heading level={2}>{t("heading")}</Heading>
        <TestimonialsGrid>
          {Array.isArray(testimonials) &&
            testimonials.map((testimonial, index) => (
              <TestimonialCard key={index}>
                <Separator />
                {testimonial.content}
                <br />
                <TestimonialAuthor>{testimonial.author}</TestimonialAuthor>
              </TestimonialCard>
            ))}
        </TestimonialsGrid>
      </Container>
    </Section>
  )
}

export default Testimonials
